<template>
  <div id="event-registrations">
    <!-- {{event.attendees}} -->
    <!-- <h1>Registrations</h1> -->
    <div class="cta-container">
      <div class="info-container">
        <h1>Registrants</h1>
        <p>
          {{
            isLoadingInfo
              ? "Loading..."
              : `${
                  event.attendees && event.attendees.total
                } Total registrations (${
                  event.attendees && event.attendees.checkin
                } check - ins)`
          }}
        </p>
      </div>
      <div class="cta">
        <v-btn
          class="exportbtn"
          color="#CC4786"
          large
          @click="exportRegistrants()"
          >Export Registrants</v-btn
        >
        <v-btn color="#CC4786" large @click="showCreateNewDialog"
          >Add Registrant</v-btn
        >
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-group stretch">
        <div class="filter stretch">
          <v-text-field
            v-model="queryString"
            color="#CC4786"
            height="40"
            outlined
            block
            prepend-inner-icon="search"
            placeholder="Find by name, email or store name"
          ></v-text-field>
        </div>
        <div class="filter">
          <v-select
            color="#CC4786"
            v-model="selectedStore"
            height="40"
            outlined
            block
            :items="lancome_stores"
            placeholder="Select store name"
            item-text="label"
            return-object
          ></v-select>
        </div>
      </div>
      <div class="filter-group">
        <div class="filter">
          <v-select
            color="#CC4786"
            v-model="registrationStatus"
            height="40"
            outlined
            block
            :items="['All', 'Confirmed', 'Checkin']"
            placeholder="Status"
            item-text="name"
            return-object
          ></v-select>
        </div>
        <!-- <div class="filter">
                    <div class="single-radio-input">
                        <v-switch
                            color="#CC4786"
                            v-model="switchCheckins"
                            :label="`Checked - in`"
                        ></v-switch>
                    </div>
                </div>
                <div class="filter">
                    <div class="single-radio-input">
                        <v-switch
                            color="#CC4786"
                            v-model="switchRegistered"
                            :label="`Registered`"
                        ></v-switch>
                    </div>
                </div> -->
      </div>
    </div>
    <br />
    <div class="no-data" v-if="attendees.length === 0">
      <h1>No results found</h1>
    </div>
    <!-- {{order_by}} - {{order_dir}} -->
    <div class="results-table" v-if="attendees.length > 0">
      <p>Total Results: {{ totalResults }}</p>
      <div class="results">
        <template>
          <v-data-table
            :headers="headers"
            :items="attendees"
            :options.sync="options"
            :server-items-length="totalResults"
            hide-default-footer
            :loading="loading"
            class="elevation-0"
          >
            <template v-slot:item.status="{ item }">
              <span :class="item.status">
                {{ item.status }}
              </span>
            </template>

            <template v-slot:item.block="{ item }">
              <v-checkbox
                color="#CC4786"
                :input-value="
                  item.block === 'true' ||
                  item.block === true ||
                  item.block === '1' ||
                  item.block === 1
                "
                @click.stop="updateBanStatus(item)"
              ></v-checkbox>
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn
                v-if="item.id > 0"
                color="#CC4786"
                @click="showSeeDetailsForm(item.id)"
              >
                Edit
              </v-btn>
            </template>
          </v-data-table>
        </template>

        <v-overlay :style="overlay">
          <template>
            <v-card>
              <div flat color="#fff" background-color="#fff" style="heigth: 45px; background: #fff">
                <div
                  style="margin-left: 94%; cursor: pointer"
                  @click="closeSeeDetailsForm()"
                >
                  <v-icon @click="closeSeeDetailsForm()" large color="#777777">
                    close
                  </v-icon>
                </div>
              </div>
              <v-tabs vertical background-color="#fff">
                <v-tab style="color: #000"> User Info </v-tab>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <div class="tab-child">
                        <div class="form">
                          <TextInput
                            label="First Name"
                            type="text"
                            v-model="firstName"
                            :value="firstName"
                            placeholder="First Name"
                            :invalid="
                              $v.firstName.$dirty && $v.firstName.$invalid
                            "
                            :errorMessage="firstNameError"
                            @input="$v.firstName.$touch()"
                          />
                          <TextInput
                            label="Last Name"
                            type="text"
                            v-model="lastName"
                            :value="lastName"
                            placeholder="Last Name"
                            :invalid="
                              $v.lastName.$dirty && $v.lastName.$invalid
                            "
                            :errorMessage="lastNameError"
                            @input="$v.lastName.$touch()"
                          />
                          <TextInput
                            label="email"
                            placeholder="name@company.com"
                            v-model="email"
                            :value="email"
                            :invalid="$v.email.$dirty && $v.email.$invalid"
                            :errorMessage="emailError"
                            @input="$v.email.$touch()"
                          />
                          <TextInput
                            label="Phone"
                            type="tel"
                            placeholder="Phone"
                            @keyup.native="phoneNumberMask($event)"
                            v-model="phone"
                            :value="phone"
                            :invalid="$v.phone.$dirty && $v.phone.$invalid"
                            :errorMessage="phoneError"
                            @input="$v.phone.$touch()"
                          />

                          <label style="font-weight: ">Store name</label>
                          <v-select
                            color="#CC4786"
                            v-model="formStore"
                            height="40"
                            outlined
                            block
                            :items="
                              lancome_stores.filter((el) => el.label !== 'All')
                            "
                            light
                            placeholder="Select store"
                            item-text="label"
                            return-object
                          ></v-select>

                          <label style="font-weight: 700"
                            >What is your clients’ #1 skin wish?</label
                          >
                          <div class="goals">
                            <div class="col">
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Protected Skin"
                                value="protected skin"
                              ></v-checkbox>
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Radiant Skin"
                                value="radiant skin"
                              ></v-checkbox>
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Correct Dark Spots/Uneven Skin Tone"
                                value="correct dark spots/uneven skin tone"
                              ></v-checkbox>
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Diminish Wrinkles"
                                value="diminish wrinkles"
                              ></v-checkbox>
                            </div>
                            <div class="col">
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Lift & Firm"
                                value="lift & firm"
                              ></v-checkbox>
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Hydration"
                                value="hydration"
                              ></v-checkbox>
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Balanced Skin"
                                value="balanced skin"
                              ></v-checkbox>
                              <v-checkbox
                                color="#CC4786"
                                light
                                v-model="formGoals"
                                label="Smoother Texture"
                                value="smoother texture"
                              ></v-checkbox>
                            </div>
                          </div>
                          <div class="events">
                            <div class="title">
                              <h4>Events</h4>
                            </div>
                            <div class="items">
                              <div
                                v-for="(element, index) in events"
                                :key="element.uuid"
                                class="event"
                              >
                                <v-checkbox
                                  color="#CC4786"
                                  light
                                  :label="element.startDate | formatDate"
                                  :value="events[index].enable"
                                  v-model="events[index].enable"
                                ></v-checkbox>
                              </div>
                            </div>
                          </div>

                          <div class="controls">
                            <v-btn
                              color="#B6BDBD"
                              large
                              @click="closeSeeDetailsForm()"
                              >Cancel</v-btn
                            >

                            <v-btn
                              class="savebtn"
                              color="#CC4786"
                              large
                              :disabled="$v.$invalid"
                              @click="changeAttendeeEventSave()"
                              >Edit Registrant</v-btn
                            >
                          </div>

                          <!-- <pre>Is invalid: {{this.$v.$invalid}}</pre> -->
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </template>
        </v-overlay>
      </div>

      <div class="controls">
        <div class="result-amount">
          <label>Displaying</label>
          <v-select
            outlined
            color="#CC4786"
            v-model="displayResults"
            height="40"
            :items="displayResultsOptions"
          ></v-select>
        </div>
        <div class="pagination">
          <v-pagination
            class="elevation-0"
            color="#CC4786"
            v-model="currentPage"
            :length="availablePages"
            :total-visible="7"
          ></v-pagination>
        </div>
        <div class="go-to-page">
          <label>Page</label>
          <v-select
            outlined
            color="#CC4786"
            v-model="currentPage"
            height="40"
            :items="availablePagesList"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="overlay">
      <v-overlay :value="showCreateDialog" scrollable>
        <div class="create-edit-form">
          <div class="title">
            <h1>{{ mode }}</h1>
            <v-icon
              @click="showCreateDialog = !showCreateDialog"
              large
              color="#777777"
            >
              close
            </v-icon>
          </div>
          <div class="form">
            <TextInput
              label="First Name"
              v-model="firstName"
              type="text"
              placeholder="First Name"
              :invalid="$v.firstName.$dirty && $v.firstName.$invalid"
              :errorMessage="firstNameError"
              @input="$v.firstName.$touch()"
            />
            <TextInput
              label="Last Name"
              v-model="lastName"
              type="text"
              placeholder="Last Name"
              :invalid="$v.lastName.$dirty && $v.lastName.$invalid"
              :errorMessage="lastNameError"
              @input="$v.lastName.$touch()"
            />
            <TextInput
              label="email"
              v-model="email"
              placeholder="name@company.com"
              :invalid="$v.email.$dirty && $v.email.$invalid"
              :errorMessage="emailError"
              @input="$v.email.$touch()"
            />
            <TextInput
              label="Phone"
              v-model="phone"
              type="tel"
              placeholder="Phone"
              @keyup.native="phoneNumberMask($event)"
              :invalid="$v.phone.$dirty && $v.phone.$invalid"
              :errorMessage="phoneError"
              @input="$v.phone.$touch()"
            />

            <label>Store name</label>
            <v-select
              color="#CC4786"
              v-model="formStore"
              height="40"
              outlined
              block
              :items="lancome_stores.filter((el) => el.label !== 'All')"
              light
              placeholder="Select store"
              item-text="label"
              return-object
            ></v-select>

            <label>What is your clients’ #1 skin wish?</label>
            <div class="goals">
              <div class="col">
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Protected Skin"
                  value="protected skin"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Radiant Skin"
                  value="radiant skin"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Correct Dark Spots/Uneven Skin Tone"
                  value="correct dark spots/uneven skin tone"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Diminish Wrinkles"
                  value="diminish wrinkles"
                ></v-checkbox>
              </div>
              <div class="col">
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Lift & Firm"
                  value="lift & firm"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Hydration"
                  value="hydration"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Balanced Skin"
                  value="balanced skin"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Smoother Texture"
                  value="smoother texture"
                ></v-checkbox>
              </div>
            </div>
            <!--div class="goals">
              <div class="col">
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Length"
                  value="Length"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Definition"
                  value="Definition"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Clean volume"
                  value="Clean volume"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="False lash look"
                  value="False lash look"
                ></v-checkbox>
              </div>
              <div class="col">
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Lift"
                  value="Lift"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Separation"
                  value="Separation"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Buildable volume"
                  value="Buildable volume"
                ></v-checkbox>
                <v-checkbox
                  color="#CC4786"
                  light
                  v-model="formGoals"
                  label="Lash extensions look"
                  value="Lash extensions look"
                ></v-checkbox>
              </div>
            </div-->

            <div class="controls">
              <v-btn
                color="#B6BDBD"
                light
                plain
                large
                @click="showCreateDialog = !showCreateDialog"
                >Cancel</v-btn
              >
              <v-btn
                color="#CC4786"
                large
                @click="handleFormSubmit"
                :disabled="$v.$invalid"
                >Add Registrant</v-btn
              >
            </div>

            <!-- <pre>Is invalid: {{this.$v.$invalid}}</pre> -->
          </div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>
<script>
import TextInput from "../../components/TextInput/TextInput.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import EventModel from "../../lib/EventModel";
import lancome_stores from "../../lib/lancome_stores.json";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";
import axios from "axios";
import XLSX from "xlsx";

export default {
  components: { TextInput },
  props: ["event"],
  data() {
    return {
      lancome_stores,
      isLoadingInfo: false,
      eventInfo: {
        registrations: 3470,
        checkins: 2673,
      },
      stores: [{ name: "All" }, { name: "A" }],
      selectedStore: null,
      queryString: "",
      switchCheckins: false,
      switchRegistered: false,
      attendees: [{ enable: false }],
      displayResults: 10,
      currentPage: 1,
      totalResults: 0,
      displayResultsOptions: [10, 25, 50, 100],
      overlay: "display:none",
      events: null,
      selectedAttendeeID: 0,
      headers: [
        { text: "First Name", value: "firstname" },
        { text: "Last Name", value: "lastname" },
        { text: "Email", value: "email" },
        { text: "Store Name", value: "store" },
        { text: "STE", value: "ste" },
        { text: "Status", value: "status" },
        { text: "Banned", value: "block", sortable: false },
        { text: "See Details", value: "id", sortable: false },
      ],
      loading: false,
      options: {},
      showCreateDialog: false,
      mode: "create",
      eventsUpdate: [],
      //Form Variables
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      store: "",
      skinwish: "",
      formStore: null,
      formGoals: [],
      registrationStatus: "",
      awaitingSearch: false,
      order_by: "email",
      order_dir: "DESC",
      event_id: null,
      socket: io(CONSTANTS.SOCKET_SERVER_URL, {
        transports: ["websocket", "polling"],
      }),
    };
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
    },
    lastName: {
      required,
      minLength: minLength(4),
    },
    email: {
      minLength: minLength(4),
      email,
    },
    phone: {
      minLength: minLength(4),
    },
    formGoals: {
      required,
    },
  },
  computed: {
    availablePages() {
      return Math.ceil(this.totalResults / this.displayResults);
    },
    availablePagesList() {
      let pages = [];
      for (let i = 0; i < this.availablePages; i++) {
        pages.push(i + 1);
      }
      return pages;
    },
    firstNameError() {
      return this.getErrorMessage(this.$v.firstName);
    },
    lastNameError() {
      return this.getErrorMessage(this.$v.lastName);
    },
    emailError() {
      return this.getErrorMessage(this.$v.email);
    },
    phoneError() {
      return this.getErrorMessage(this.$v.phone);
    },
  },
  methods: {
    showCreateNewDialog() {
      this.mode = "create";

      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.formStore = null;
      this.formGoals = [];

      this.$v.firstName.$reset();
      this.$v.lastName.$reset();
      this.$v.email.$reset();
      this.$v.phone.$reset();

      this.showCreateDialog = true;
    },
    registerEvents() {
      if (!this.socket) {
        console.warn("NO SOCKET YET!!!");
        return;
      }

      this.socket.on("connect", (param) => {
        console.log("connected");
        console.log(param);
      });

      this.socket.emit("chat:signin", {
        type: "signin",
        event: `lancome-admin-${this.event._id}`,
        id: 1,
      });

      this.socket.on("chat:room", () => {
        console.log("Connected to ");
        console.log(`lancome-admin-${this.event._id}`);
      });
      // Reconnect
      this.socket.on("reconnect", () => {
        this.socket.emit("chat:signin", {
          type: "reconnect",
          event: `lancome-admin-${this.event._id}`,
          id: 1,
        });
      });
    },
    async fetchResults() {
      console.log(this.options);

      try {
        let filter = {};
        console.log(filter);
      } catch (e) {
        debugger;
        console.error(e);
      }

      this.loading = false;
    },
    getErrorMessage(model) {
      if (model == null || !model.$dirty) {
        return "";
      }

      let message = "";
      // debugger;
      if (typeof model.required !== "undefined" && !model.required) {
        message = "This field is required";
      }
      if (typeof model.minLength !== "undefined" && !model.minLength) {
        message =
          "You must enter at least " +
          model.$params.minLength.min +
          " characters";
      }
      if (
        typeof model.sameAsPassword !== "undefined" &&
        !model.sameAsPassword
      ) {
        message = "Entered value doesn't match";
      }
      if (typeof model.email !== "undefined" && !model.email) {
        message = "Value must be valid email";
      }

      return message;
    },
    isFormValid() {
      let isValid = true;

      if (this.$v.email && this.$v.email.$invalid) {
        isValid = false;
      }
      if (this.$v.password && this.$v.password.$invalid) {
        isValid = false;
      }
      console.log(this.$v);
      return isValid;
    },
    async handleFormSubmit() {
      let store = this.formStore ? this.formStore.value : "";
      let goals =
        this.formGoals &&
        Array.isArray(this.formGoals) &&
        this.formGoals.length > 0
          ? this.formGoals.join()
          : "";

      let attendee = {
        firstname: this.firstName.trim(),
        lastname: this.lastName.trim(),
        email: this.email.trim() || "",
        event: this.event.sandia,
        phone: this.phone || "",
        store: store,
        skinwish: goals,
      };
      // console.log(attendee);
      // debugger;
      await this.runFunctionWithLoader(async () => {
        await EventModel.addNewAttendee(attendee);
        this.showCreateDialog = false;
      });
    },

    async updateBanStatus(item) {
      // alert(item.id);
      let event_id = this.event._id;
      let attendee_id = item.id;

      if (
        item.block === "true" ||
        item.block === true ||
        item.block === "1" ||
        item.block === 1
      ) {
        await EventModel.blockUser(item, false);
        item.block = 0;
      } else {
        await EventModel.blockUser(item, true);
        item.block = 1;

        this.socket.emit("attendee:block", {
          event: event_id,
          id: attendee_id,
        });
      }
    },

    async getFilteredAttendeeInfo() {
      let firstRecord = (this.currentPage - 1) * this.displayResults || 0;
      let status =
        this.registrationStatus &&
        this.registrationStatus.toLowerCase() !== "all"
          ? this.registrationStatus.toLowerCase()
          : "";
      let store =
        this.selectedStore &&
        typeof this.selectedStore.value === "string" &&
        this.selectedStore.value.toLowerCase() !== "all"
          ? this.selectedStore.value.trim()
          : "";
      // let order_by = (this.object && this.object.sortBy && this.object.sortBy[0])?this.object.sortBy[0]:'email';
      // let order_dir = (this.object && this.object.sortDesc && this.object.sortDesc[0] && this.object.sortDesc[0] === false)?'ASC':'DESC';
      // debugger;

      let filter = {
        first_record: firstRecord,
        record_limit: this.displayResults,
        order_by: this.order_by,
        order_dir: this.order_dir,
        term: this.queryString,
        status: status,
        store: store,
      };

      let count,
        users;

      let sandiaEvent = this.event.sandia;
      
      
      try {
      //  ({ count, users } = await EventModel.getFilteredAttendees(
      //     this.event.sandia,
      //     filter
      //   ));
       let filteredResponse = await EventModel.getFilteredAttendees(
          sandiaEvent,
          filter
        );

        count = filteredResponse.count || 0;
        users = filteredResponse.users || 0;
        debugger;
      } catch (e) {
        if (e) {
          console.log(e);
          debugger;
        }
      }

      this.totalResults = count || 0;
      this.attendees = users || [];
      this.event_id = this.event._id;
    },
    async updateEventAttendeeInfo() {
      if (!this.event) {
        return;
      }
      let attendees = await EventModel.getAttendeeCount(this.event.sandia);
      this.$set(this.event, "attendees", attendees);
    },
    async refreshAttendeeInfo() {
      if (this.loading || !this.event || !this.event.sandia) {
        return;
      }
      this.loading = true;
      // await this.runFunctionWithLoader(this.getFilteredAttendeeInfo);
      await this.getFilteredAttendeeInfo();
      await this.updateEventAttendeeInfo();
      this.loading = false;
    },

    async runFunctionWithLoader(userFunction) {
      if (typeof userFunction === "function") {
        try {
          this.$emit("show_loader");
          await userFunction();
        } catch (error) {
          console.error(error);
          debugger;
        } finally {
          this.$emit("hide_loader");
        }
      }
    },
    showSeeDetailsForm(attendeeID) {
      const url = CONSTANTS.SANDIA_API_BASE + "attendee/" + attendeeID;
      this.selectedAttendeeID = attendeeID;
      for (let a = 0; a < this.events.length; a++)
        this.events[a].enable = false;

      axios
        .post(url, {})
        .then((result) => {
          let attendees = result.data;
          this.firstName = attendees[0].firstname;
          this.lastName = attendees[0].lastname;
          this.email = attendees[0].email;
          this.phone = attendees[0].phone;
          this.formStore = {
            label: attendees[0].store,
            value: attendees[0].store,
          };
          this.skinwish = attendees[0].skinwish;
          if (attendees[0].skinwish != null)
            this.formGoals = attendees[0].skinwish.split(",");
          else this.formGoals = [];

          for (let i = 0; i < attendees.length; i++) {
            for (let a = 0; a < this.events.length; a++) {
              if (attendees[i].event_id == this.events[a].sandia) {
                this.events[a].enable = true;
              }
            }
          }
          //this.eventsUpdate = ;
          this.overlay = "display:flex;";
        })
        .catch((e) => {
          console.log(e);
          alert("there was an error please try later!");
        });
    },
    closeSeeDetailsForm() {
      this.overlay = "display:none";
      this.selectedAttendeeID = 0;
    },
    getEvents() {
      let url = CONSTANTS.API_MONGO_BASE_URL + "api/events";
      let config = {
        headers: {
          "x-token-auth": localStorage.getItem("token"),
        },
      };

      axios
        .get(url, config)
        .then(async (result) => {
          if (result.data) {
            let tempEvent = result.data.data.items.filter((el) => {
              if (el._id == this.event._id) this.$set(el, "enable", true);
              else this.$set(el, "enable", false);

              if (el.active) {
                return el;
              }
            });
            this.events = tempEvent;
            this.eventsUpdate = tempEvent;
            await Promise.all(
              this.events.map(async (event) => {
                let attendees = await EventModel.getAttendeeCount(event.sandia);
                this.$set(event, "attendees", attendees);
              })
            );
          }
        })
        .catch((error) => {
          alert("An error has ocurred");
          console.error(error);
        });
    },
    changeAttendeeEventSave() {
      this.updateAttendee();
      for (let i = 0; i < this.events.length; i++) {
        this.changeAttendeeEvent(i);
      }
      this.closeSeeDetailsForm();
    },
    changeAttendeeEvent(eventArrayPosition) {
      let uri = "";
      if (this.events[eventArrayPosition].enable) {
        //dar de alta
        uri = `${this.selectedAttendeeID}/addevent/${this.events[eventArrayPosition].sandia}`;
      } else {
        //dar de baja
        uri = `${this.selectedAttendeeID}/removeevent/${this.events[eventArrayPosition].sandia}`;
      }
      this.updateAttendeeEventStatus(uri);
    },
    updateAttendeeEventStatus(uri) {
      const url = CONSTANTS.SANDIA_API_BASE + "attendee/" + uri;

      axios
        .post(url, {})
        .then(() => {
          if (uri.indexOf("removeevent") > -1) this.refreshAttendeeInfo();
        })
        .catch((e) => {
          console.log(e);
          alert("there was an error please try later!");
        });
    },
    async exportRegistrants() {
      let exportAttendees = [];

      let count,
        users = 0;

      let filter = {
        first_record: 0,
        record_limit: 1000000,
        order_by: this.order_by,
        order_dir: this.order_dir,
        term: this.queryString,
      };


      try {
        ({ count, users } = await EventModel.getFilteredAttendees(
          this.event.sandia,
          filter
        ));
      } catch (e) {
        if (e) {
          console.log(e);
        }
      }

      console.log( count);
      for (let i = 0; i < users.length; i++) {
        exportAttendees.push({
          "First Name": users[i].firstname,
          "Last Name": users[i].lastname,
          Email: users[i].email,
          Store: users[i].store,
          STE: users[i].ste ?? '',
          "Skin Wish": users[i].skinwish,
          Banned: users[i].block == 0 ? "No" : "Yes",
          Phone: users[i].phone,
          Status: users[i].status,
        });
      }

      const Registrants = XLSX.utils.json_to_sheet(exportAttendees);
      const workBook = {
        Title: "hello",
        SheetNames: ["Registrants"], // sheet name
        Sheets: {
          Registrants: Registrants,
        },
      };

      XLSX.writeFile(workBook, "Registrants.xlsx");
    },
    phoneNumberMask(e) {
      let ph = this.phone.replace(/\D/g, "").substring(0, 10);

      const deleteKey = e ? e.keyCode === 8 || e.keyCode === 46 : false;
      const len = ph.length;
      if (len > 0 && len < 3) {
        ph = `(${ph}`;
      } else if (len === 3) {
        ph = `(${ph}${deleteKey ? "" : ") "}`;
      } else if (len < 6) {
        ph = `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}`;
      } else if (len === 6) {
        ph = `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}${
          deleteKey ? "" : "-"
        }`;
      } else {
        ph = `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}-${ph.substring(
          6,
          10
        )}`;
      }

      if (String(ph).trim() === "()") {
        ph = "";
      }
      this.phone = ph;
    },
    updateAttendee() {
      const skinwishpref = this.formGoals.join(",");
      let attendee = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        phone: this.phone,
        store: this.formStore.value,
        skinwish: skinwishpref,
      };
      const url =
        CONSTANTS.SANDIA_API_BASE + `attendee/${this.selectedAttendeeID}/edit`;

      axios
        .post(url, attendee)
        .then(() => {
          this.refreshAttendeeInfo();
        })
        .catch((e) => {
          console.log(e);
          alert("there was an error please try later!");
        });

      this.closeSeeDetailsForm();
    },
    //lastmethod
  },
  watch: {
    selectedStore(newVal) {
      if (newVal && newVal.label === "All") {
        this.selectedStore = null;
      }
      this.currentPage = 1;
      this.refreshAttendeeInfo();
    },
    registrationStatus(newVal) {
      if (newVal && newVal.toLowerCase() === "all") {
        this.registrationStatus = null;
      }
      this.currentPage = 1;
      this.refreshAttendeeInfo();
    },
    displayResults() {
      this.currentPage = 1;
      this.refreshAttendeeInfo();
    },
    currentPage() {
      this.refreshAttendeeInfo();
    },
    queryString() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.currentPage = 1;
          this.refreshAttendeeInfo();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    // totalResults(){
    //     this.currentPage = 1;
    //     //this.fetchResults();
    // },
    // attendees(){
    //     this.totalResults = this.attendees.length;
    // },
    options: {
      handler(newVal) {
        // console.log(this.options);
        // debugger;
        this.order_by =
          newVal && newVal.sortBy && newVal.sortBy[0]
            ? newVal.sortBy[0]
            : "email";
        this.order_dir =
          newVal && newVal.sortDesc && newVal.sortDesc[0] ? "DESC" : "ASC";
        this.refreshAttendeeInfo();
      },
      deep: true,
    },
    event() {
      if (this.event) {
        this.refreshAttendeeInfo();
      }
    },
  },
  mounted() {
    this.refreshAttendeeInfo();
    if (this.events == null) this.getEvents();
  },
};
</script>
<style lang="scss">
#event-registrations {
  .v-btn.v-btn--disabled {
    background-color: rgb(204, 71, 134);
    border-color: rgb(204, 71, 134);
    opacity: 0.8;
  }

  // fieldset{
  //         color: #E4E8E8 ;
  //         // border-color: #E4E8E8 ;
  // }

  // .v-text-field.v-text-field--outlined{
  //     border-color: #E4E8E8 ;
  // }

  // fieldset.v-input--is-focused{
  //     border-color:red !important;
  // }

  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #e4e8e8;
  }
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #e4e8e8;
  }

  margin: 43px 0 0 auto;
  // width: 90%;
  // max-width: 1200px;
  .cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;

    .info-container {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-flow: column;
      h1 {
        font-family: graphikmedium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #363738;
        margin: 0;
      }
      p {
        font-family: graphikregular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #6c6e6f;
        margin: 0;
      }
    }
  }

  .filter-container {
    margin-top: 5px;
    display: flex;
    flex-flow: row wrap;
    .filter-group {
      display: flex;
      flex-flow: row wrap;
      .filter {
        display: flex;
        flex-flow: row wrap;
        margin-left: 5px;

        .v-text-field__details {
          display: none;
        }

        .v-input__slot {
          background-color: white;
        }

        .v-input__prepend-inner {
          z-index: 10;
        }
      }
    }
  }

  .single-radio-input {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e8e8;
    background-color: white;
    padding: 0 12px;
    border-radius: 4px;
  }

  .v-input--selection-controls__input {
    .v-label {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 140%;
    }
  }

  .stretch {
    flex-grow: 1;
    flex-shrink: 0;
  }
  .noshrink {
    flex-shrink: 0;
  }

  .outlined {
    border: 1px solid red;
    border-radius: 50%;
    height: 56px;
    width: 56px;
  }

  .no-data {
    border: 1px solid #e4e8e8;
    background-color: white;
    padding: 0 12px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;

    h1 {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;
    }
  }

  .controls {
    margin-top: 25px;
    display: flex;
    .result-amount,
    .go-to-page {
      width: 10%;
    }
    .pagination {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .result-amount {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      display: flex;
      align-items: center;
      label {
        margin-right: 5px;
      }

      .v-text-field__details {
        display: none;
      }
      .mdi-menu-down::before {
        background: none;
        width: 0;
        height: 0;
      }
      .v-input__append-inner {
        display: none;
      }
    }
    .go-to-page {
      font-family: graphikregular;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      display: flex;
      align-items: center;
      label {
        margin-right: 5px;
      }

      .v-text-field__details {
        display: none;
      }
      .mdi-menu-down::before {
        background: none;
        width: 0;
        height: 0;
      }
      .v-input__append-inner {
        display: none;
      }
    }

    .v-pagination__navigation,
    .v-pagination__item {
      box-shadow: none;
      background-color: transparent;
      outline: none;
    }
    .v-pagination__item.v-pagination__item--active {
      color: #cc4786;
      background-color: transparent !important;
      // text-decoration: underline;
      border-bottom: 1px solid #cc4786;
      border-radius: 0;
    }

    .v-icon.notranslate.mdi.mdi-chevron-left,
    .v-icon.notranslate.mdi.mdi-chevron-right {
      color: #cc4786;
    }
  }

  th {
    background-color: #e4e8e8;
    text-transform: uppercase;
    font-family: graphikmedium;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.25em;
    text-align: center;
    color: #cc4786;
  }

  td {
    font-family: graphikregular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .registered,
  .confirmed {
    color: #23adfa;
  }

  .checkin {
    color: #19ccac;
  }

  .create-edit-form {
    background: #ffffff;
    // box-shadow: 0px 20px 40px rgba(255, 240, 250, 0.8);
    border-radius: 8px;
    padding: 25px;

    width: 95vw;
    max-width: 465px;

    .title {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i:hover {
        cursor: pointer;
      }
      h1 {
        color: #363738;
        font-family: graphikmedium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: capitalize;
      }
    }
    .lancome-textfield label {
      color: #363738;
    }
    .form-input > input {
      width: 100%;
    }

    .v-application.error,
    .lancome-textfield.error {
      background-color: transparent !important;
      label {
        color: red !important;
      }
    }
    color: #363738;
    *::placeholder {
      color: A2A9A9, 100%;
    }
    .v-select__selections > * {
      color: #363738;
      *::placeholder {
        color: A2A9A9, 100%;
      }
    }

    .v-input__control {
      color: #e4e8e8;
    }

    label {
      font-family: graphikmedium;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #363738;
      display: block;
    }

    .goals {
      display: flex;
      justify-content: row wrap;
      .col {
        min-width: 50%;
      }

      .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
        > .v-input__control
        > .v-input__slot
        fieldset {
        color: #e4e8e8;
      }
      .v-icon.v-icon {
        color: #e4e8e8;
      }
      .v-messages.theme--light {
        display: none;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
    }

    .v-input--checkbox {
      .v-label {
        font-family: graphikregular;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        color: #363738;
      }
    }

    .controls {
      display: flex;
      justify-content: space-between;

      .v-btn.v-btn--flat.v-btn--plain {
        span {
          color: #b6bdbd !important;
        }
      }
    }
  }
}

.tab-child {
  width: 500px;
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  padding-right: 2em;
  .goals {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .col {
      div {
        margin-bottom: 0;
        margin-top: 0;
        .v-messages {
          display: none;
        }
      }
    }
  }
  .form-input {
    input {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .v-text-field__details {
    display: none;
  }
  label {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
  .events {
    .title {
      text-align: left;
    }
    label {
      color: #000;
    }
    .items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .event{
        div{
          margin-top:0 ;
          margin-bottom: 0;
        }
        .theme--light.v-messages{
          display: none;
        }
      }

    }
  }
  .savebtn {
    margin-left: 2em;
  }

  .lancome-textfield.error {
    background-color: #fff !important;
  }
}
.v-tab--active {
  background: #cbcdce;
}
.exportbtn {
  margin-right: 1em;
}
.normalLabel {
  label {
    text-transform: none;
  }
}

.mdi-menu-down::before {
    transform: rotateX(180deg);
}
.v-card__text{
  padding-top: 0 !important;
}
</style>