import axios from 'axios';
import CONSTANTS from './Constants';

const GameModel = {
    save : (gameObj)=>{

        return new Promise((resolve,reject)=>{
            try{
                //Validate if there is an event id associated
                if(gameObj.event == null || gameObj.event.trim() === ""){
                    reject('Invalid event id provided');
                }

                //Validate if there is an event id associated
                if(gameObj.question == null || gameObj.question.trim() === ""){
                    reject('Invalid question string provided');
                }

                let mode = 'create';
                //Determine if we need to call save or edit method
                if(typeof(gameObj._id) !== 'undefined'){
                    mode = 'edit';
                }

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };

                let payload = {
                    // "event": gameObj.event,
                    "question": gameObj.question.trim(),
                    // "type": gameObj.type,
                    "position": gameObj.position
                };
                
                let base_url = CONSTANTS.API_MONGO_BASE_URL;
                if(mode === 'create'){

                    payload.event = gameObj.event;
                    payload.type = gameObj.type;

                    let url = base_url + `/api/games/`;

                    axios.post(url, payload, config).then(response =>{
                        // console.log(response.data);
                        // debugger;
                        resolve(response.data);
                    }).catch(error => {
                        // console.log(error);
                        // debugger;
                        reject(error);
                    });
                }
                else if(mode === 'edit'){
                    let urlEdit = base_url + `api/games/${gameObj._id}`;
                    
                    axios.put(urlEdit, payload, config).then(response =>{
                        // console.log(response.data);
                        // debugger;
                        resolve(response.data);
                    }).catch(error => {
                        // console.log(error);
                        // debugger;
                        reject(error);
                    });
                }
            }
            catch(e){
                reject(e);
            }


        });


    },

    updateStatus:(gameObj,status) =>{
        

        return new Promise((resolve,reject)=>{
            try{
                let base_url = CONSTANTS.API_MONGO_BASE_URL;
                let urlEdit = base_url + `api/games/${gameObj._id}`;
                let newStatus = status.toLowerCase().trim();
                let payload = {
                    status: newStatus
                }

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };
                    
                axios.put(urlEdit, payload, config).then(response =>{
                    // console.log(response.data);
                    // debugger;
                    resolve(response.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });
            }
            catch(e){
                reject(e);
            }
        });
    },
    updateIntermissionStatus:(eventObj,status) =>{
        

        return new Promise((resolve,reject)=>{
            try{
                let base_url = CONSTANTS.API_MONGO_BASE_URL;
                let urlEdit = base_url + `api/events/${eventObj._id}`;
                let newStatus = status.toLowerCase().trim();
                let payload = {
                    intermission: newStatus
                }

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };
                 
                axios.put(urlEdit, payload, config).then(response =>{
                    // console.log(response.data);
                    // debugger;
                    resolve(response.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });
            }
            catch(e){
                reject(e);
            }
        });
    },
    updatePosition:(gameObj) =>{
        

        return new Promise((resolve,reject)=>{
            try{
                if(!gameObj.position){
                    resolve(true);
                }
                let base_url = CONSTANTS.API_MONGO_BASE_URL;
                let urlEdit = base_url + `api/games/${gameObj._id}`;
                
                let payload = {
                    position: gameObj.position
                }

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };
                    
                axios.put(urlEdit, payload, config).then(response =>{
                    // console.log(response.data);
                    // debugger;
                    resolve(response.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });
            }
            catch(e){
                reject(e);
            }
        });
    },

    updateShowHideResults:(gameObj, showOrHide)=>{
        return new Promise((resolve,reject)=>{
            try{
                let base_url = CONSTANTS.API_MONGO_BASE_URL;
                let urlEdit = base_url + `api/games/${gameObj._id}`;
                
                let payload = {
                    showResults: showOrHide
                }

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };
                    
                axios.put(urlEdit, payload, config).then(response =>{
                    // console.log(response.data);
                    // debugger;
                    resolve(response.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });
            }
            catch(e){
                reject(e);
            }
        });
    },

    saveAnswers : (gameObj)=>{
        
        const config = {
            headers:{
                'x-token-auth': localStorage.getItem('token')
            }
        };


        const createNewAnswer = (event_id,option)=>{
            return new Promise((resolve,reject)=>{
                try{
                    
                    let payload = option;
                    const base_url = CONSTANTS.API_MONGO_BASE_URL;
                    let url = base_url + `api/games/${event_id}/answers/`;
                    
                    axios.post(url, payload, config).then(response =>{
                        // console.log(response.data);
                        // debugger;
                        resolve(response.data);
                    }).catch(error => {
                        // console.log(error);
                        // debugger;
                        reject(error);
                    });
                }
                catch(err){
                    reject(err);
                }
            });
        };

        const editAnswer = (event_id,option)=>{
            return new Promise((resolve,reject)=>{
                try{
                    
                    let payload = option;
                    const base_url = CONSTANTS.API_MONGO_BASE_URL;
                    let url = base_url + `api/games/${event_id}/answers/${option._id}`;
                    
                    axios.put(url, payload, config).then(response =>{
                        console.log(response.data);
                        // debugger;
                        resolve(response.data);
                    }).catch(error => {
                        // console.log(error);
                        // debugger;
                        reject(error);
                    });
                }
                catch(err){
                    reject(err);
                }
            });
        };


        return new Promise((resolve,reject)=>{
           
            try{
                let options = gameObj.options;
                let event_id = gameObj._id;

                options = options.map((option,index) => {
                    option.position = index;
                    return option;
                });

                let newOptions = options.filter(option => {
                    return option._id == null
                });
                let editOptions = options.filter(option => {
                    return option._id !== null
                });
                
                newOptions = newOptions.map((option,index)=>{
                    let tempOption = {
                        "isCorrect": option.isCorrect || false,
                        "active": option.isActive || true,
                        "answer": (option.answer)?option.answer.trim():"",
                        "position": option.position || index
                    }
                    return tempOption;
                });

                editOptions = editOptions.map((option,index)=>{
                    let tempOption = {
                        "isCorrect": option.isCorrect || false,
                        "active": option.isActive || true,
                        "_id": option._id || null,
                        "answer": (option.answer)?option.answer.trim():"",
                        "position": option.position || index
                    }
                    return tempOption;
                });


                //Save new Options
                Promise.all(newOptions.map(async option => {
                    return await createNewAnswer(event_id, option);
                    
                })).then(()=>{
                    //Edit options
                    Promise.all(editOptions.map( async option => {
                        return await editAnswer(event_id, option);
                    })).then(()=>{
                        resolve(true);
                    });
                    //resolve(true);
                });

            }
            catch(err){
                reject(err);
            }
          
        });
    
    },
    
    deleteAnswers : (event_id, deleteOptions)=>{
        
        const deleteAnswer = (event_id,option) =>{
            return new Promise((resolve,reject)=>{
                try{
                    
                    //let payload = option;
                    const base_url = CONSTANTS.API_MONGO_BASE_URL;
                    let url = base_url + `api/games/${event_id}/answers/${option._id}`;
                    let config = {
                        headers:{
                            'x-token-auth': localStorage.getItem('token')
                        }
                    };
                    axios.delete(url, config).then(response =>{
                        // console.log(response.data);
                        // debugger;
                        resolve(response.data);
                    }).catch(error => {
                        // console.log(error);
                        // debugger;
                        reject(error);
                    });
                }
                catch(err){
                    reject(err);
                }
            });
        };


        return new Promise((resolve,reject)=>{
            try{
                //Edit options
                Promise.all(deleteOptions.map( async option => {
                    return await deleteAnswer(event_id, option);
                })).then(()=>{
                    resolve(true);
                });
            }
            catch(error){
                reject(error);
            }
        });

        // console.log(answer);
        //return new Promise((resolve,reject)=>{});
    
    },
    deleteGame:(gameObj) => {
        return new Promise((resolve,reject)=>{
            try{
                if(typeof(gameObj._id) === 'undefined'){
                    reject('Missing id for deletion');
                }
                

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };

                
                
                let url = CONSTANTS.API_MONGO_BASE_URL + `/api/games/${gameObj._id}`;

                axios.delete(url, config).then(response =>{
                    // console.log(response.data);
                    // debugger;
                    resolve(response.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });






            }
            catch(e){
                reject(e);
            }
        });
    },

    getEventGames:(event_id) => {
        return new Promise((resolve,reject)=>{
            try{
                if(typeof(event_id) === 'undefined'){
                    reject('Missing id fetching games');
                }
                

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };

                
                
                let url = CONSTANTS.API_MONGO_BASE_URL + `api/games/?event=${event_id}&per_page=1000`;

                axios.get(url, config).then(response =>{
                    // console.log(response.data);
                    // debugger;
                    resolve(response.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });

            }
            catch(e){
                reject(e);
            }
        });
    },
    
    getGameResults:(gamesArray) => {

        const getGameResult = (result_id) => {
            return new Promise((resolve,reject)=>{
                try{
                    if(typeof(result_id) === 'undefined'){
                        reject('Missing id for fetching game result');
                    }
    
                    let config = {
                        headers:{
                            'x-token-auth': localStorage.getItem('token')
                        }
                    };
    
                    let url = CONSTANTS.API_MONGO_BASE_URL + `/api/games/${result_id}/results`;
    
                    axios.get(url, config).then(response =>{
                        // console.log(response.data);
                        // debugger;
                        let result = response.data;
                        if(Array.isArray(result.data.options)){
                           result.data.options = result.data.options.sort((a,b)=>a.position-b.position);
                        }
                        

                        resolve(result);
                    }).catch(error => {
                        // console.log(error);
                        // debugger;
                        reject(error);
                    });
    
                }
                catch(e){
                    reject(e);
                }
            });
        };

        return new Promise((resolve,reject)=>{
            try{
                if(!Array.isArray(gamesArray)){
                    resolve(false);
                }
                let results = [];
                
                Promise.all(gamesArray.map(async (game)=>{
                    let result = await getGameResult(game._id);
                    
                    if(result){
                        results.push(result);
                    }
                })).then(()=>{
                    resolve(results);
                });
                
            }
            catch(e){
                reject(e);
            }
        });
    },



    getEventGamesWithResults: async (eventObj) => {
        // let gameResults = [];
        let response;
        let tempGamesList;
        let resultsResponse;
        let event_id = eventObj._id;
        let rating_id = eventObj.rating;

        if(!event_id){
            return;
        }

        response = await GameModel.getEventGames(event_id);
        tempGamesList = response.data.items || [];

        // try{

            //Get games results
            resultsResponse = await GameModel.getGameResults(response.data.items);
            // if(resultsResponse){
            //     gameResults = resultsResponse;
            // }
            // else{
            //     gameResults = [];
            // }
            
        // }
        // catch(e){
        //     // gameResults = [];
        //     throw(e);
        // }


        return new Promise((resolve,reject)=>{

            try{
                
    
                //TODO: pagination
                if(Array.isArray(response.data.items)){
                    
                    //Filter only 'poll', 'trivia'
                    if(!Array.isArray(tempGamesList)){
                        tempGamesList = [];
                    }
                    
                    tempGamesList = tempGamesList.filter(res=>{
                        try{
                            let type = res.type.trim().toLowerCase();
                            
                            if(type === 'poll' || type === 'trivia'){
                                if(res.event === event_id){
                                    return true;
                                }
                            }
                            else if(type === 'rating' && res._id === rating_id){
                                return true;
                            }
                            
                        }
                        catch(e){
                            console.log(e);
                        }
                    }).filter(el => el != null);
    
                    
    
    
                    //Assign results to game
                    let aux = tempGamesList.map((game)=>{
    
    
                        //Sort options by position
                        if(game.options.length > 0){
                            game.options.sort((a,b) => a.position - b.position);
                        }
                        
                        if(!Array.isArray(resultsResponse)){
                            console.log(game);
                            resultsResponse = [];
                        }
                        
                        let availableResults = resultsResponse.filter(res=>{return res.data.question === game.question} );
                        availableResults = availableResults.map(res => res.data);
                        if(availableResults.length > 0){
                      
                            availableResults = availableResults[0];
    
                            game.options = game.options.map(gameOption =>{
                                let value = availableResults.options.find(ar=>{return ar._id === game.options[0]._id;});
                                
                                if(value){
                                    gameOption.votes = value.votes || 0;
                                    gameOption.score = value.score || 0;
                                }
                                else{
                                    gameOption.votes = 0;
                                    gameOption.score = 0;
                                }
    
                                return gameOption;
                            });
    
                            game.results = availableResults;
                            
    
                        }
                        else{
                            availableResults = null;
                        }
    
                        
                        return game;
    
                    });
    
    
                    //Assign to variable
                    // this.games = aux;
                    resolve(aux);
                }
                // else{
                //     games = [];
                //     gameResults = [];
                // }
    
              
            }
            catch(e){
                reject(e);
            }
            


        });
    },


    

    getGameAnswersStatistics: (gamesArray) =>{

        const getTotalCorrect = (gameObj)=>{
            let total = 0;
            try{
                total = gameObj.results.options.reduce((total,el)=>{
                    if(el.isCorrect && el.votes > 0){
                        return total + el.votes
                    }
                    else{
                        return total;
                    }
                },0);
            }catch{
                total = 0;
            }
            return total;
        };
        const getTotalIncorrect = (gameObj)=>{
            let total = 0;
    
            try{
                total = gameObj.results.options.reduce((total,el)=>{
                    if(!el.isCorrect && el.votes > 0){
                        return total + el.votes
                    }
                    else{
                        return total;
                    }
                },0);
            }catch{
                total = 0;
            }
            return total;
        };
        // totalResults: (gameObj)=>{
        //     return this.totalCorrect + this.totalIncorrect;
        // }



        let gameStats = gamesArray.map(game => {
            let totalCorrect = getTotalCorrect(game);
            let totalIncorrect = getTotalIncorrect(game);
            let totalAnswers = totalCorrect + totalIncorrect;
            let percentage = (totalAnswers > 0)?Math.round((totalCorrect * 100)/totalAnswers):0;

            return{
                _id: game._id,
                type: game.type,
                question: game.question,
                totalCorrect: totalCorrect,
                totalIncorrect: totalIncorrect,
                totalAnswers: totalAnswers,
                percentage: percentage
            }
        });

        return gameStats;
    }





}

export default GameModel;