<template>
    <div class="report-section registrationbreakdown">
        <div class="report-title">
            <h1>Trivia breakdown</h1>
        </div>
        <div class="report-row">
            <div class="report-card single-value nopadding">
                <h1>{{totalTrivias}}</h1>
                <p>Total trivias</p>
            </div>
            <div class="report-card dual-value nopadding">
                <h1><span class="green">{{triviaTotalCorrectAnswers}}</span> <span class="grey">/</span> {{triviaTotalAnswers}}</h1>
                <p>Total correct / Total answers</p>
            </div>
            <div class="report-card single-value nopadding">
                <h1>{{triviaCorrectPercentage}}%</h1>
                <p>Correct Percentage</p>
            </div>
        </div>




    <!-- {{availableTriviasStats}} -->
        <div class="report-row">
            <template>
            <v-data-table
                :headers="triviaHeaders"
                :items="availableTriviasStats"
                :items-per-page="5"
                class="elevation-1"
            ></v-data-table>
            </template>

        </div>








        <div class="report-title">
            <h1>Polls breakdown</h1>
            <!-- <p></p> -->
        </div>
        <div class="report-row">
            <div class="report-card single-value nopadding">
                <h1>{{totalPolls}}</h1>
                <p>Total polls</p>
            </div>
            <div class="report-card dual-value nopadding">
                <h1>{{pollTotalAnswers}}</h1>
                <p>Total answers</p>
            </div>
        </div>
        

        <!-- {{availableTriviasStats}} -->
        <div class="report-row">
            <template>
            <v-data-table
                :headers="pollHeaders"
                :items="availablePollsStats"
                :items-per-page="5"
                class="elevation-1"
            ></v-data-table>
            </template>

        </div>



        <!-- {{eventRatingValue}} -->
        <div class="report-title">
            <h1>Overall Event Rating : {{eventRatingValue2}}</h1>
            <!-- <p></p> -->
        </div>
        <div class="report-row ">
            <div class="report-card fullwidth">
                <v-rating
                    background-color="grey lighten-2"
                    color="warning"
                    hover
                    length="5"
                    size="64"
                    :value="eventRatingValue"
                    half-increments
                    readonly
                ></v-rating>
            </div>
            
        </div>

    </div>
</template>
<script>
import GameModel from '../../../lib/GameModel'
export default {
    props:['event','eventResults'],
    data(){
        return{
            triviaHeaders:[
                { text: 'Question', value: 'question', sortable: true },
                { text: 'Total Correct', value: 'totalCorrect', sortable: true, align: 'center' },
                { text: 'Total Answers', value: 'totalAnswers', sortable: true, align: 'center' },
                { text: '% Correct', value: 'percentage', sortable: true, align: 'center' },
                // { text: 'Type', value: 'type', sortable: true }
            ],
            pollHeaders:[
                { text: 'Question', value: 'question', sortable: true },
                { text: 'Total Responses', value: 'totalAnswers', sortable: true, align: 'center' },
                // { text: 'Type', value: 'type', sortable: true }
            ]
        }

    },
    computed:{
        checkinPercentage(){
            let totalUsers = (this.event && this.event.attendees && this.event.attendees.total)?this.event.attendees.total:0;
            let checkinUsers = (this.event && this.event.attendees && this.event.attendees.checkin)?this.event.attendees.checkin:0;
            let percentage = 0;

            if(checkinUsers === 0){
                return percentage;
            }
            percentage = Math.round((checkinUsers * 100)/totalUsers);
            return percentage;
        },
        availableGames(){
            let games = [];
            if(!this.event || !this.eventResults || !Array.isArray(this.eventResults)){
                return games;
            }
            games = this.eventResults.filter(game => game.type !== 'rating');
            return games;

        },
        eventRating(){
            let games = [];
            if(!this.event || !this.eventResults){
                return games;
            }
            games = this.eventResults.filter(game => game.type === 'rating');
            return games;
        },
        availableTriviasStats(){
            return this.gameAnswersStatistics.filter(game => game.type === 'trivia');
        },
        availablePollsStats(){
            return this.gameAnswersStatistics.filter(game => game.type === 'poll');
        },
        totalQuestions(){
            return this.availableGames.length;
        },
        totalTrivias(){
            return this.availableGames.filter(game => game.type === 'trivia').length;
        },
        totalPolls(){
            return this.availableGames.filter(game => game.type === 'poll').length;
        },
        gameAnswersStatistics(){
            return GameModel.getGameAnswersStatistics(this.availableGames);
        },
        ratingStatistics(){
            let result = GameModel.getGameAnswersStatistics(this.eventRating);
            if(result){
                return result[0];
            }
            return null;
        },
        triviaTotalCorrectAnswers(){
            if(!this.gameAnswersStatistics){
                return total;
            }
            let total = 0;
            let availableTrivias = this.gameAnswersStatistics.filter(game => game.type === 'trivia');

            if(!availableTrivias){
                return total;
            }

            total = availableTrivias.reduce((total,game)=>{
                if(!game.totalCorrect){
                    return total;
                }
                return total + game.totalCorrect
            },0);
            return total;
        },
        triviaTotalInCorrectAnswers(){
            if(!this.gameAnswersStatistics){
                return total;
            }
            let total = 0;
            let availableTrivias = this.gameAnswersStatistics.filter(game => game.type === 'trivia');

            if(!availableTrivias){
                return total;
            }

            total = availableTrivias.reduce((total,game)=>{
                if(!game.totalIncorrect){
                    return total;
                }
                return total + game.totalIncorrect
            },0);
            return total;
        },
        triviaTotalAnswers(){
            if(!this.gameAnswersStatistics){
                return total;
            }
            let total = 0;
            let availableTrivias = this.gameAnswersStatistics.filter(game => game.type === 'trivia');

            if(!availableTrivias){
                return total;
            }

            total = availableTrivias.reduce((total,game)=>{
                if(!game.totalAnswers){
                    return total;
                }
                return total + game.totalAnswers
            },0);
            return total;
        },
        triviaCorrectPercentage(){
            let percentage = 0;
            if(this.triviaTotalAnswers >0){
                percentage = Math.round((this.triviaTotalCorrectAnswers * 100) / this.triviaTotalAnswers);
            }
            return percentage;
        },
        pollTotalAnswers(){
            if(!this.gameAnswersStatistics){
                return total;
            }
            let total = 0;
            let availableTrivias = this.gameAnswersStatistics.filter(game => game.type === 'poll');

            if(!availableTrivias){
                return total;
            }

            total = availableTrivias.reduce((total,game)=>{
                if(!game.totalAnswers){
                    return total;
                }
                return total + game.totalAnswers
            },0);
            return total;
        },
        eventRatingValue(){
            if(!this.eventRating){
                return;
            }


            let ratingObj = this.eventRating[0].results;
            let ratingStats = this.ratingStatistics;
            let computedRating = 0;


            //for(let i =0; i < ratingObj.options)

            let ponderatedValues = ratingObj.options.reduce((total,option)=>{
                if(!option.answer){
                    return total
                }
                return total + (option.votes * +option.answer);
            },0);

            computedRating = (ponderatedValues ) / ratingStats.totalAnswers;
            computedRating = +computedRating.toFixed(1);
            return computedRating;
        },
        eventRatingValue2(){
            if(!this.eventRating){
                return 0;
            }


            let ratingObj = this.eventRating[0].results;
            let ratingStats = this.ratingStatistics;
            let computedRating = 0;


            //for(let i =0; i < ratingObj.options)

            let ponderatedValues = ratingObj.options.reduce((total,option)=>{
                if(!option.answer){
                    return total
                }
                return total + (option.votes * +option.answer);
            },0);

            computedRating = (ponderatedValues ) / ratingStats.totalAnswers;
            computedRating = +computedRating.toFixed(4);
            return computedRating;
        }
    }
}
</script>
<style lang="scss">
        
    .report-section{
        margin-top:40px;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;

        .fullwidth{
            width: 100%;
        }

        .report-title{
            margin-top:50px;
            margin-bottom:20px;
            text-align: left;
            h1{
                font-family: graphikmedium;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                color: #363738;
            }
        }

        .report-row{
            display:flex;
            justify-content: space-between;
            margin:15px 0;
            

            .report-card{
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                border: 1.2px solid #E4E8E8;
                box-sizing: border-box;
                border-radius: 4px;

                margin-left:15px;
                &:first-child{
                    margin-left:0;
                }

                padding:21px 30px;

                &.nopadding{
                    padding:21px 0;
                }

                &.fullwidth{
                    width: 100% !important;
                }
            
                h1{
                    font-family: graphikregular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 40px;
                    line-height: 44px;
                    color: #CC4786;
                    margin-bottom: 5px;

                    span.grey{
                        color:#E4E8E8;
                        background-color: transparent !important;
                    }
                    span.green{
                        color:#36D3B6;
                        background-color: transparent !important;
                    }
                    span.fade{
                        color: #CF9DB5;
                    }
                }
                p{
                    font-family: graphikregular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    color: #6C6E6F;
                    margin:0;
                }
                

                &.single-value{
                    flex:1;
                }
                &.dual-value{
                    flex:1.5;
                }


                .title{
                    font-family: graphikregular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.02em;
                    color: #6C6E6F;
                }
                
            }

        }

        .v-data-table{
            width:100%;
        }


        th {
            background-color: #E4E8E8;
            text-transform: uppercase;
            font-family: graphikmedium;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.25em;
            text-align: center;
            color: #CC4786;
            span{
                color: #CC4786;
            }
            // display:flex;
            
        }


    }

</style>