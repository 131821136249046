import axios from 'axios';
import CONSTANTS from './Constants';

const EventModel = {
    getAttendeeCount: async (eventName = null)=>{
        return new Promise((resolve, reject)=>{
            try{
                
                let url = CONSTANTS.SANDIA_API_BASE + 'attendee/count';
    
                axios.post(url,null,null).then(async (result)=>{
    

                    if(result.data){
                        // let data = result.data;
                        let info = {
                            confirmed:0,
                            checkin:0,
                            cancelled:0,
                            registered:0,
                            total:0
                        }

                        try{
                            for(let i =0; i < result.data.length; i++){
                                let stat = result.data[i];
                                let type, count;
                                if(eventName != null && stat.event === eventName){
                                    type = stat.status;
                                    count = +stat.attendees;
                                    info[type] = info[type] + count;
                                    info.total += count;
                                }
                                else if(eventName == null){
                                    type = stat.status;
                                    count = +stat.attendees;
                                    info[type] = info[type] + count;
                                    info.total += count;
                                }
                            }
                        }
                        catch(e){
                            console.error(e);
                            reject(info);
                        }
                        finally{
                            resolve(info);
                        }
                        
    
    
                    }
                    reject('Event Data could not be found');
                    
    
                })
                .catch(error=>{
                    // error.response.data.error.detail || "Error: Invalid credentials";
                    alert('An error has ocurred');
                    reject(error);
                    debugger;
                    
                });
    
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        });
    },


    getTotalAttendees: (eventName = null)=>{
        return new Promise((resolve, reject)=>{
            try{
                
                let url = this.CONSTANTS.SANDIA_API_BASE + 'attendee/count';
    
                axios.post(url,null,null).then(async (result)=>{
    
                    // console.log(result);
                    if(result.data){
                        let data = result.data;
                        let totalRegistrations = 0;
                        
    
    
                        //Get total registrations
                        try{
                            totalRegistrations = data.reduce((total,entry)=>{
                                    let attendees = 0;
                                    if(eventName){
                                        if(entry.event === eventName){
                                            attendees = (entry.attendees)?entry.attendees:0;
                                        }
                                    }
                                    else{
                                        attendees = (entry.attendees)?entry.attendees:0;
                                    }
                                    return total + attendees;
                                },0);
                        }
                        catch(e){
                            console.error(e);
                            totalRegistrations = 0;
                        }
                        finally{
                            resolve(totalRegistrations);
                        }
                        
    
                    }
                    return 0;
                    
    
                })
                .catch(error=>{
                    // error.response.data.error.detail || "Error: Invalid credentials";
                    alert('An error has ocurred');
                    reject(error);
                    
                });
    
            }
            catch(e){
                reject(e);
            }
        });
    },


    getFilteredAttendees: async (eventName,filter = {}) =>{
        if(typeof(eventName) !== 'string'){
            return;
        }
        return new Promise((resolve, reject)=>{
            try{
                const url = CONSTANTS.SANDIA_API_BASE + 'attendee/searchLancome/' + eventName;
                // const attendeeTemplate = {
                //                 firstname: '',
                //                 lastname: '',
                //                 email: '',
                //                 store: '',
                //                 status: ''
                //             };
                console.log(eventName, filter);
                axios.post(url,filter).then((result)=>{
                    let attendees = result.data;
                    console.log(attendees);
                    resolve(attendees);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },



    addNewAttendee: async (attendee) =>{
        if(!attendee){
            return;
        }
        return new Promise((resolve, reject)=>{
            try{
                const url = CONSTANTS.SANDIA_API_BASE + 'attendee/add/';
                // const attendeeTemplate = {
                //                 firstname: '',
                //                 lastname: '',
                //                 email: '',
                //                 store: '',
                //                 status: ''
                //             };
                // debugger;
                axios.post(url,attendee).then((result)=>{
                    // debugger;
                    // console.log(result);
                    resolve(result);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },

    getRegistrationsAndCheckinOverTime: async (event_id,filter = null)=>{
        return new Promise((resolve, reject)=>{
            try{
                // debugger;
                const url = CONSTANTS.SANDIA_API_BASE + `dashboard/${event_id}/registration`;
                axios.post(url,filter).then((result)=>{
                    // debugger;
                    resolve(result.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },

    getStoreAttendeeTotals: async (event_id,filter = null)=>{
        return new Promise((resolve, reject)=>{
            try{
                const url = CONSTANTS.SANDIA_API_BASE + `dashboard/${event_id}/stores/general`;
                axios.post(url,filter).then((result)=>{
                    resolve(result.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    getAttendeeGoalsTotals: async (event_id,filter = null)=>{
        return new Promise((resolve, reject)=>{
            try{
                const url = CONSTANTS.SANDIA_API_BASE + `dashboard/${event_id}/skinwish`;//lashgoals
                axios.post(url,filter).then((result)=>{
                    resolve(result.data.chains);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    getEventQuestions: async (eventObj,filter = null)=>{
        return new Promise((resolve, reject)=>{
            try{
                const event_id = eventObj._id;
                //const url = CONSTANTS.SANDIA_API_BASE + `dashboard/${event_id}/lashgoals`;

                let queryString = (filter != null && typeof(filter.query) === 'string' && filter.query.trim().length > 0)?`&search=${filter.query.trim()}`:'';
                let statusFilter = (filter != null && typeof(filter.status) === 'string' && filter.status.trim().length > 0)?`&status=${filter.status.trim()}`:'';
                let highlightedFilter = (filter != null && filter.highlighted === true)?`&favorite=true`:'';
                

                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };

                const url = CONSTANTS.API_MONGO_BASE_URL +`api/messages?event=${event_id}&page=all${statusFilter}${queryString}${highlightedFilter}`;
                axios.get(url,config).then((result)=>{
                    resolve(result.data.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },

    updateQuestionStatus: async (messageObj)=>{
        return new Promise((resolve, reject)=>{
            try{
                const message_id = messageObj._id;
              
                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };

                let payload = {
                    "status": messageObj.status
                }

                const url = CONSTANTS.API_MONGO_BASE_URL +`api/messages/${message_id}`;
                axios.put(url,payload,config).then((result)=>{
                    resolve(result.data.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    updateQuestionFavoriteStatus: async (messageObj)=>{
        return new Promise((resolve, reject)=>{
            try{
                const message_id = messageObj._id;
              
                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };

                let payload = {
                    "favorite": messageObj.favorite
                }

                const url = CONSTANTS.API_MONGO_BASE_URL +`api/messages/${message_id}`;
                axios.put(url,payload,config).then((result)=>{
                    resolve(result.data.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },

    blockUser: async (user,block = true)=>{
        return new Promise((resolve, reject)=>{
            try{
                const user_id = user.id;
                const blockString = (!block)?'/false':''
                const url = CONSTANTS.SANDIA_API_BASE + `attendee/${user_id}/block${blockString}`;
                axios.post(url).then((result)=>{
                    resolve(result.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },


    getStore2Totals: async (event_id,filter = null)=>{

        return new Promise((resolve, reject)=>{
            try{
                //let event_id = eventObj.sandia;
                const url = CONSTANTS.SANDIA_API_BASE + `dashboard/${event_id}/stores`;

                // let queryString = (filter != null && typeof(filter.query) === 'string' && filter.query.trim().length > 0)?`&search=${filter.query.trim()}`:'';
                // let statusFilter = (filter != null && typeof(filter.status) === 'string' && filter.status.trim().length > 0)?`&status=${filter.status.trim()}`:'';
                // let highlightedFilter = (filter != null && filter.highlighted === true)?`&favorite=true`:'';
                
                axios.post(url,filter).then((result)=>{
                    resolve(result.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },




}

export default EventModel;