import axios from 'axios';
import CONSTANTS from './Constants';

const ChatModel = {
    getEventChatMessages:(event_id, filter = null) => {
        return new Promise((resolve,reject)=>{
            try{
                if(typeof(event_id) === 'undefined'){
                    reject('Missing id fetching games');
                }
                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };
                let queryString = (filter != null && typeof(filter.query) === 'string' && filter.query.trim().length > 0)?filter.query.trim():'';

                let url = CONSTANTS.API_MONGO_BASE_CHAT_URL + `api/chat/eventChats/${event_id}/`;

                if( queryString != '' )
                    url = CONSTANTS.API_MONGO_BASE_CHAT_URL + `api/chat/eventUserChats/${event_id}/${queryString}`;
                ///
                console.log(queryString);
                axios.get(url, config).then(response =>{
                    // console.log(response.data);
                    resolve(response.data.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });

            }
            catch(e){
                reject(e);
            }
        });
    },
    deleteEventChatMessages:(message_id) => {
        return new Promise((resolve,reject)=>{
            try{
                if(typeof(message_id) === 'undefined'){
                    reject('Missing id for deleting chat message');
                }
                let config = {
                    headers:{
                        'x-token-auth': localStorage.getItem('token')
                    }
                };
                
                let url = CONSTANTS.API_MONGO_BASE_CHAT_URL + `api/chat/eventChat/${message_id}`;                

                axios.delete(url, config).then(response =>{
                    // console.log(response.data);
                    // debugger;
                    resolve(response.data.data);
                }).catch(error => {
                    // console.log(error);
                    // debugger;
                    reject(error);
                });

            }
            catch(e){
                reject(e);
            }
        });
    },
}

export default ChatModel;