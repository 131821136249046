<template>
    <!-- <h1>Games</h1> -->
    <div id="event-games">
        <div class="cta-container">
            <div class="info-container">
                <!-- {{this.allowEmit}} -->
                <h1>Live event games</h1>
                <p></p>
            </div>
            <div class="cta">
                <v-btn
                color="#CC4786"
                large
                @click="showCreateNewGame"
                >Create New</v-btn>
            </div>
        </div>
        <div class="intermission">
            <v-switch
                color="#CC4786"
                v-model="showIntermission"
                @click="handleIntermissionClick"
            ></v-switch>
            <div class="legend">
                <h1>Intermission</h1>
                <p>Turn on and off the Eiffel Tower intermission experience</p>
            </div>
        </div>
        <div class="intermission">
            <v-switch
                color="#CC4786"
                v-model="showRatings"
                @click="handleRatingsClick"
            ></v-switch>
            <div class="legend">
                <h1>Ratings</h1>
                <p>Turn on and off ratings</p>
            </div>
        </div>

        <!-- {{(eventRatingObj && eventRatingObj.status)?eventRatingObj.status:null}}
        {{showRatings}} -->
        
        <div class="games-container" v-if="games.filter(g=> g.type !== 'rating' && g.event === event._id).length > 0">
            <draggable 
                v-model="games"
                handle=".handle"
                @end="saveGamePosition"
            >
                <transition-group>
                    <Game v-for="(game) in games.filter(g=> g.type !== 'rating' && g.event === event._id)" 
                        :key="game._id" 
                        :game="game"
                        :totalLiveGames="totalLiveGames"
                        v-on:deletegame="handleDelete(game)"
                        v-on:editgame="handleEdit(game)"
                        v-on:golive="handleGoLive(game)"
                        v-on:endgame="handleEndGame(game)"
                        v-on:showhideresults="handleShowHideResults(game)"
                    />
                    <!-- <pre>{{event._id}}</pre> -->
                    <!-- <pre>{{this.games.map(game => game.question)}}</pre> -->
                </transition-group>
            </draggable>
        </div>

        <div class="no-games-container" v-else>
            <div class="image-container">
                <img :src="lancome_create_game" alt="Create new Game">
                <h1>Create a game</h1>
                <p>
                    Create a poll or trivia question for attendees
                </p>
                <v-btn
                    color="#CC4786"
                    large
                    @click="showCreateNewGame"
                >Create Game</v-btn>
            </div>
        </div>


        <div v-if="showCreateDialog" class="overlay-container">
            <!-- <v-overlay :value="showCreateDialog"> -->
                <div class="create-edit-form">

                    

                    <div class="title">
                        <h1>{{mode}}</h1>
                        <v-icon
                            @click="showCreateDialog = !showCreateDialog"
                            large
                            color="#777777"
                        >
                        close
                        </v-icon>
                    </div>
                    <div class="form">
                        
                        <label v-if="mode === 'create'" class="name">Game Type</label>
                        <div v-if="mode === 'create'" class="game-type-container">
                            <div class="game-type" @click="changeEventType('trivia')" :class="{selected: this.newEvent.type === 'trivia'}">
                                <!-- <span :class="{'icon-selected':  newEvent.type === 'trivia', 'icon-default': newEvent.type !== 'trivia'}"></span> -->
                                <span v-if="newEvent.type === 'trivia'" class="icon-selected"></span>
                                <span v-else class="icon-default"></span>
                                <label>Trivia</label>
                            </div>
                            <div class="game-type" @click="changeEventType('poll')" :class="{selected: this.newEvent.type === 'poll'}">
                                <!-- <span :class="{'icon-selected': newEvent.type === 'poll', 'icon-default': newEvent.type !== 'poll'}"></span> -->
                                <span v-if="newEvent.type === 'poll'" class="icon-selected"></span>
                                <span v-else class="icon-default"></span>
                                <label>Poll</label>
                            </div>
                        </div>

                        <TextInput 
                            label='Question'
                            v-model="newEvent.question"
                            type="text"
                            placeholder=""
                            :invalid="false"                        
                        />

                        <label v-if="validAnswers && validAnswers.length > 0" class="name">Answers</label>
                        
                        <draggable 
                            v-model="newEvent.options"
                            handle=".handle"
                        >
                            <transition-group>
                                <div v-show="element.delete == null || element.delete === false" class="answer-entry" v-for="(element,index) in newEvent.options" :key="'asdf'+index">
                                    <div class="handle">
                                        <img :src="lancome_handle" alt="handle">
                                    </div>
                                    <div v-if="newEvent.type === 'trivia'" class="answer-type">
                                        <img v-if="element && element && element.isCorrect" @click="$set(element,'isCorrect', false)" :src="lancome_answer_correct" alt="lancome_answer_correct">
                                        <img v-else @click="$set(element,'isCorrect', true)" :src="lancome_answer_default" alt="lancome_answer_default">
                                    </div>
                                   <v-text-field
                                        placeholder="Enter answer"
                                        outlined
                                        dense
                                        light
                                        color="#CC4786"
                                        v-model="element.answer"
                                    ></v-text-field>
                                    <div class="actions-container" v-if="validAnswers && validAnswers.length > 2">
                                        <img @click="$set(element,'delete', true)" :src="lancome_trash" alt="Delete answer">
                                    </div>
                                </div>
                            </transition-group>
                        </draggable>
                        
                        <div @click="addNewAnswerToQuestion" class="add-new-container">
                            <v-icon light>add</v-icon>
                            Add new answer
                        </div>
                        <!-- <pre>{{newEvent}}</pre> -->


                        <div class="controls">
                            <v-btn
                                color="#B6BDBD"
                                light
                                plain
                                large
                                @click="showCreateDialog = !showCreateDialog"
                            >Cancel</v-btn>
                            <v-btn
                                :disabled="!isGameFormValid"
                                color="#CC4786"
                                large
                                @click="createNewGame"
                            >Save</v-btn>
                        </div>

                    </div>
                </div>
            <!-- </v-overlay> -->
        </div>


        <div class="confirm-dialog">
            <v-dialog
                persistent
                v-model="showConfirmDialog"
                max-width="350"
            >
            <v-card>
                <v-card-title class="headline">
                {{confirmDialogTitle}}
                </v-card-title>

                <v-card-text class="dialog-body">
                {{confirmDialogBody}}
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <div class="confirm-controls" style="width=100%">
                    <v-btn
                        plain
                        large
                        color="#CC4786 darken-1"
                        light
                        @click="confirmInput(false)"
                    >
                        Disagree
                    </v-btn>

                    <v-btn
                        color="#CC4786"
                        large
                        
                        @click="confirmInput(true)"
                    >
                        Agree
                    </v-btn>
                </div>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>



    </div>
</template>
<script>
import Game from '../../components/Game/Game.vue';
import TextInput from '../../components/TextInput/TextInput.vue';
import draggable from 'vuedraggable';
import lancome_handle from '../../assets/img/lancome_handle.svg';
import lancome_trash from '../../assets/img/lancome_trash.svg';
import lancome_answer_correct from '../../assets/img/lancome_answer_correct.svg';
import lancome_answer_default from '../../assets/img/lancome_answer_default.svg';
import lancome_create_game from '../../assets/img/lancome_create_game.svg';
import GameModel from '../../lib/GameModel';
import io from 'socket.io-client';
import CONSTANTS from '../../lib/Constants';


export default {
    components:{
        Game,
        TextInput,
        draggable
    },
    props:['event'],
    
    data(){
        return {
            
            isSocketConnected: false,
            socketMessage: '',
            
            showIntermission:null,
            showRatings:null,
            lancome_handle,
            lancome_trash,
            lancome_answer_correct,
            lancome_answer_default,
            lancome_create_game,
            games:[
                // {
                //     "type": "poll",
                //     "showResults": false,
                //     "active": true,
                //     "_id": "5ff25f515e32b777b7144837",
                //     "event": "5fec1650d7c8987621a30c6b",
                //     "question": "What's your favorite color?",
                //     "position": 1,
                //     "options": [
                //         {
                //             "isCorrect": false,
                //             "active": true,
                //             "_id": "5ff25ffe5e32b777b7144838",
                //             "answer": "Yellow & Blue",
                //             "position": 1
                //         },
                //         {
                //             "isCorrect": false,
                //             "active": true,
                //             "_id": "5ff25ffe5e32b777b7144839",
                //             "answer": "Green",
                //             "position": 2
                //         }
                //     ],
                //     "createdAt": "2021-01-04T00:20:33.617Z",
                //     "__v": 0
                // }
            ],
            showCreateDialog:false,
            showConfirmDialog:false,
            confirmDialogTitle:'Do you want to proceed?',
            confirmDialogBody:'This action cannot be undone',
            confirmYesCallback:null,
            confirmNoCallback:null,
            mode:'create',
            // newEvent:{type:'trivia'}
            newEvent:{
                type: "trivia",
                question: "",
                answers:[
                    {
                        "isCorrect": false,
                        "active": true,
                        "_id": null,
                        "answer": "",
                        "position": null,
                        "votes": 0,
                        "score": 0
                    },
                    {
                        "isCorrect": false,
                        "active": true,
                        "_id": null,
                        "answer": "",
                        "position": null,
                        "votes": 0,
                        "score": 0
                    }
                ]
            },
            gameResults:[],
            socket: io(CONSTANTS.SOCKET_SERVER_URL,{transports: ['websocket', 'polling']}),
            allowEmit:true,
            initialized:false,
            timer:null,
            forceRecomputeCounter: 0
        }
    },
    methods:{

        recomputeRating() {
            this.forceRecomputeCounter++;
        },

        async initialize(){
            try{
                setTimeout(()=>{
                        this.registerEvents();
                    },500);
                if(!this.event){
                    return;
                }
                if(this.event.intermission){
                    this.showIntermission = (this.event.intermission === 'live')?true:false;
                }
                if(!this.socket ){
                    this.socket = io(CONSTANTS.SOCKET_SERVER_URL,{transports: ['polling','websocket']});
                }
               
                await this.getEventGames();
                this.showRatings = (this.eventRatingObj && this.eventRatingObj.status === 'live')?true:false;
                
                setTimeout(()=>{
                        this.registerEvents();
                    },500);
            }
            catch(err){
                console.error(err);
            }
            finally{
                
                this.initialized = true
            }
        },
        
        async getEventGames(show = true){
            try{
                if(!this.event._id){
                    return;
                }
                if(show){
                    this.$emit('show_loader');
                }
                
                
                let response = await GameModel.getEventGames(this.event._id);
                //TODO: pagination
                if(Array.isArray(response.data.items)){
                    let tempGamesList = response.data.items || [];
                    //Get games results
                    let resultsResponse = await GameModel.getGameResults(response.data.items);
                    if(resultsResponse){
                        this.gameResults = resultsResponse;
                    }
                    else{
                        this.gameResults = [];
                    }


                    //Filter only 'poll' or 'trivia'
                    if(!Array.isArray(tempGamesList)){
                        tempGamesList = [];
                    }
                    
                    tempGamesList = tempGamesList.filter(res=>{
                        try{
                            
                            switch(res.type.trim().toLowerCase()){
                                case 'poll':
                                case 'trivia':
                                    return true;
                                case 'rating':
                                    if(res._id === this.event.rating){
                                        return true;
                                    }
                            }
                        }
                        catch(e){
                            console.log(e);
                        }
                    }).filter(el => el != null);

                    


                    //Assign results to game
                    let aux = tempGamesList.map((game)=>{


                        //Sort options by position
                        if(game.options.length > 0){
                            game.options.sort((a,b) => a.position - b.position);
                        }
                        
                        if(!Array.isArray(resultsResponse)){
                            console.log(game);
                            resultsResponse = [];
                        }
                        
                        let availableResults = resultsResponse.filter(res=>{return res.data.question === game.question} );
                        availableResults = availableResults.map(res => res.data);
                        if(availableResults.length > 0){
                      
                            availableResults = availableResults[0];

                            game.options = game.options.map(gameOption =>{
                                let value = availableResults.options.find(ar=>{return ar._id === game.options[0]._id;});
                                
                                if(value){
                                    gameOption.votes = value.votes || 0;
                                    gameOption.score = value.score || 0;
                                }
                                else{
                                    gameOption.votes = 0;
                                    gameOption.score = 0;
                                }

                                return gameOption;
                            });

                            game.results = availableResults;
                            

                        }
                        else{
                            availableResults = null;
                        }

                        
                        return game;

                    });


                    //Assign to variable
                    this.games = aux;
                }
                else{
                    this.games = [];
                    this.gameResults = [];
                }

              
            }
            catch(e){
                alert(e);
            }
            finally{
                this.$emit('hide_loader');
            }
        },
        showCreateNewGame(){
            this.mode = 'create';
            this.newEvent = {
                type: "trivia",
                question: "",
                options:[
                    {
                "isCorrect": false,
                "active": true,
                "_id": null,
                "answer": "",
                "position": null,
                "votes": 0,
                "score": 0
            },
            {
                "isCorrect": false,
                "active": true,
                "_id": null,
                "answer": "",
                "position": null,
                "votes": 0,
                "score": 0
            }
                ]
            };
            this.showCreateDialog = !this.showCreateDialog;
        },
        async createNewGame(){
            try{
                this.showCreateDialog = false;
                this.$emit('show_loader');

                //Bind event id
                this.newEvent.event = this.event._id;
                //Create editable event instance object
                let eventObjCopy = {...this.newEvent};
                eventObjCopy.options = this.validAnswers.map((ans,index)=>{
                    ans.position = index;
                    return ans;
                });
                // console.log(eventObjCopy);

                //Store to DB
                let savedEvent = await GameModel.save(eventObjCopy);
                eventObjCopy._id = savedEvent.data._id;
                
                
                //Store question answers
                await GameModel.saveAnswers(eventObjCopy);
                // console.log(savedEvent2);
                
                
                //Delete answers by demand
                let markedAnswersForDelete = this.deletedAnswers;
                
                if(markedAnswersForDelete.length > 0){
                    await GameModel.deleteAnswers(eventObjCopy._id,markedAnswersForDelete);
                    // console.log(result);
                }
                

                //this.games.push(this.newEvent);
                this.getEventGames();
            }
            catch(e){
                //console.log(e);
                alert(e);
            }
            finally{
                this.$emit('hide_loader');
                
            }
        },
        changeEventType(type){

            this.$set(this.newEvent, 'type', type);
           
        },
        addNewAnswerToQuestion(){
            
            // this.showCreateDialog = false;
            let answers = this.newEvent.options;
            let answersCopy;
            if(!Array.isArray(answers)){
                answers = [{
                "isCorrect": false,
                "active": true,
                "_id": null,
                "answer": "",
                "position": null,
                "votes": 0,
                "score": 0
            },
            {
                "isCorrect": false,
                "active": true,
                "_id": null,
                "answer": "",
                "position": null,
                "votes": 0,
                "score": 0
            }
            ];
            }
            answersCopy = [...answers];
            let newAnswer = {
                "isCorrect": false,
                "active": true,
                "_id": null,
                "answer": "",
                "position": null,
                "votes": 0,
                "score": 0
            }
            answersCopy.push(newAnswer);

            this.$set(this.newEvent, 'options', answersCopy);
        },

        confirmInput(result){
            // alert('confirminput '+ result)
            this.showConfirmDialog = false;
            if(result && typeof(this.confirmYesCallback) === 'function'){
                this.confirmYesCallback();
            }
            else if(!result && typeof(this.confirmNoCallback) === 'function'){
                this.confirmNoCallback();
            }
        },
        configureConfirmDialog({title, body, yescb = null, nocb = null}){
            this.confirmDialogTitle = title;
            this.confirmDialogBody = body;
            this.confirmYesCallback = yescb;
            this.confirmNoCallback = nocb;
        },
        displayConfirmDialog(config){
            return new Promise((resolve)=>{
                this.showConfirmDialog = true;
                config.yescb = () => {resolve(true)};
                config.nocb = () => {resolve(false)};
                this.configureConfirmDialog(config);
            });
        },


        async handleDelete(game){
            try{
                //Bind event id
                this.newEvent.event = this.event._id;
                let userConfirmed = await this.displayConfirmDialog({
                    title:"Please confirm",
                    body: "Do you want to delete this item? This action cannot be undone."
                });
                if(!userConfirmed){
                    return;
                }
                this.$emit('show_loader');

                //Delete from DB
                // console.log(game);
                await GameModel.deleteGame(game);
                


                this.getEventGames();
            }
            catch(e){
                //console.log(e);
                alert(e);
            }
            finally{
                this.$emit('hide_loader');
                
            }
        },

        handleEdit(game){
            this.mode = 'edit';
            this.newEvent = {};
            this.showCreateDialog = true;
            this.newEvent = {...game};
        },
        async handleEndGame(game){
            try{
                //Bind event id
                this.newEvent.event = this.event._id;
                let userConfirmed = await this.displayConfirmDialog({
                    title:"Please confirm",
                    body: "Do you want to end this game? This action cannot be undone."
                });
                if(!userConfirmed){
                    return;
                }
                this.$emit('show_loader');

                //Update status in DB
                // console.log(game);
                await GameModel.updateStatus(game,'ended');
                game.status = 'ended';


                let params = {
                            event: this.event._id,
                            game: game._id
                        }
                let action = (game.status === 'live')?'start':'end';
                // console.log(`game:${action}`);
                if(this.allowEmit){
                    console.log(this.socket.emit(`game:${action}`,params));
                }
                // console.log(this.socket.emit(`game:${action}`,params));

                this.getEventGames();
            }
            catch(e){
                //console.log(e);
                alert(e);
            }
            finally{
                this.$emit('hide_loader');
                
            }
        },
        async handleGoLive(game){
            try{
                //Bind event id
                this.newEvent.event = this.event._id;
                let userConfirmed = await this.displayConfirmDialog({
                    title:"Please confirm",
                    body: "Do you want to launch this game? This action cannot be undone."
                });
                if(!userConfirmed){
                    return;
                }
                this.$emit('show_loader');

                //Update status in DB
                // console.log(game);
                await GameModel.updateStatus(game,'live');
                game.status = 'live';


                let params = {
                            event: this.event._id,
                            game: game._id
                        }
                let action = (game.status === 'live')?'start':'end';
                // console.log(`game:${action}`);
                // this.socket.emit(`game:${action}`,params);
                if(this.allowEmit){
                    this.socket.emit(`game:${action}`,params);
                }

                this.getEventGames();
            }
            catch(e){
                //console.log(e);
                alert(e);
            }
            finally{
                this.$emit('hide_loader');
                
            }
        },
        async handleShowHideResults(game){
            try{
                //Bind event id
                //this.newEvent.event = this.event._id;
                let userConfirmed = await this.displayConfirmDialog({
                    title:"Please confirm",
                    body: "Do you want to perform this action?"
                });
                if(!userConfirmed){
                    game.showResults = !game.showResults;
                    return;
                }
                this.$emit('show_loader');

                
                //Update status in DB
                let newStatus = game.showResults;
                await GameModel.updateShowHideResults(game, newStatus);
                

                this.getEventGames();
            }
            catch(e){
                game.showResults = !game.showResults;
                //console.log(e);
                alert(e);
            }
            finally{
                this.$emit('hide_loader');
                
            }
        },

        async saveGamePosition(){
            //Get all games and clone the object
            this.games  = this.games.map((game,index)=>{
                game.position = index;
                return game;
            });
            console.log(this.games);
            
               
            //Save status to DB
            try{
                this.$emit('show_loader');
                await Promise.all(
                     this.games.map(async (game)=>{
                        await GameModel.updatePosition(game);
                    })
                );
            }
            catch(err){
                // debugger;
                console.log(err);
            }
            finally{
                // debugger;
                this.$emit('hide_loader');
            }



        },

        connectToSocket(){

        },
        disconnectFromSocket(){
           
        },
        registerEvents(){
            if(!this.socket){
                console.warn('NO SOCKET YET!!!');
                return;
            }

            this.socket.on('connect', (param)=>{
                console.log('connected'); 
                console.log(param); 
                
            });

            this.socket.emit('chat:signin', {
                                            type: 'signin',
                                            event: `lancome-admin-${this.event._id}`,
                                            id: 1
                                        });




            this.socket.on('chat:room',()=>{
                    console.log('Connected to ');
                    console.log(`lancome-admin-${this.event._id}`);
                });

            this.socket.on('intermission:start', () =>{
                // alert("received")
               
                this.showIntermission = true;
                
            });
            this.socket.on('intermission:end', () =>{
                // alert("receivedend")

                this.showIntermission = false;

            });

            // Reconnect
            this.socket.on('reconnect', () => {
                // reconnect
                this.socket.emit('chat:signin',{
                                            type: 'reconnect',
                                            event: `lancome-admin-${this.event._id}`,
                                            id: 1
                                        });
            });


            this.socket.on('game:received', async (data) =>{
                const self = this;
                if(!data || this.games.length === 0){
                    return;
                }
                // console.log(data);


                // try{
                //     this.allowEmit = false;
                //     await this.getEventGames();
                // }
                // catch(err){
                //     console.error(err);
                // }
                // finally{
                //     this.allowEmit = true;
                // }


                try{
                   
                    let games = [...self.games];
                    // console.log(games);
                    let elementIndex = -1;

                    // alert(JSON.stringify(data));
                    // console.log('*********',data);
                    // debugger;

                    let gameToUpdate = games.find((game,index)=>{
                        if(game._id === data._id){
                            elementIndex = index;
                            return true;
                        }
                    });
                    // console.log(elementIndex);
                    
                    if(gameToUpdate){
                        gameToUpdate.options = [...data.options];
                        gameToUpdate.results.options = [...data.options];
                        self.$set(gameToUpdate,'options',[...data.options]);
                        self.$set(gameToUpdate.results,'options',[...data.options]);
                        self.$set(self.games, elementIndex, gameToUpdate);
                        self.games[elementIndex] = gameToUpdate;
                        //this.getEventGames();
                        
                    }
                }
                catch(err){
                    console.error(err);
                }
                finally{
                    this.allowEmit = true;
                }


                // let games = [...self.games];
                // console.log(games);
                // let elementIndex = -1;
                // let gameToUpdate = games.find((game,index)=>{
                //     if(game._id === data._id){
                //         elementIndex = index;
                //         return true;
                //     }
                // });
                // console.log(elementIndex);
                
                // if(gameToUpdate){
                //     gameToUpdate.options = [...data.options]
                //     self.$set(self.games, elementIndex, gameToUpdate);
                //     this.getEventGames();
                // }
            });

            this.socket.on('game:ended', async (data) =>{
                // alert('Game end');
                // this.getEventGames();
                // try{
                //     this.allowEmit = false;
                //     await this.getEventGames();
                // }
                // catch(err){
                //     console.error(err);
                // }
                // finally{
                //     this.allowEmit = true;
                // }




                const self = this;
                if(!data || this.games.length === 0){
                    return;
                }
                // alert('Game start');
                // this.getEventGames();
                try{
                    // this.allowEmit = false;
                    // await this.getEventGames();
                    

                    let games = [...self.games];
                    // console.log(games);
                    let elementIndex = -1;

                    //alert(JSON.stringify(data));
                    // console.log('*********',data);
                    // debugger;

                    let gameToUpdate = games.find((game,index)=>{
                        if( game._id === data.game){
                            elementIndex = index;
                            return true
                        }
                    });
                    // console.log(elementIndex,gameToUpdate);
                    // debugger;
                    
                    if(gameToUpdate){
                        // if(gameToUpdate.status === 'ended'){
                        //     return;
                        // }
                        // gameToUpdate.options = [...data.options]
                        // gameToUpdate.status = 'ended';


                        // self.$set(gameToUpdate,'status','ended');
                        // self.$set(self.games, elementIndex, gameToUpdate);


                        //self.games[elementIndex] = gameToUpdate;
                        //this.getEventGames();


                        self.$set(self.games[elementIndex], 'status', 'ended');
                        this.recomputeRating();
                    }


                }
                catch(err){
                    console.error(err);
                }
                finally{
                    this.allowEmit = true;
                }




            });
            this.socket.on('game:live', async(data) =>{
                const self = this;
                
                if(!data || this.games.length === 0){
                    return;
                }
                // alert('Game start');
                //alert(JSON.stringify(data));
                // debugger
                // this.getEventGames();
                try{
                    // this.allowEmit = false;
                    // await this.getEventGames();
                    

                    let games = [...self.games];
                    // console.log(games);
                    let elementIndex = -1;

                    //alert(JSON.stringify(data));
                    console.log('*********',data);
                    // debugger;

                    let gameToUpdate = games.find((game,index)=>{
                        if( game._id === data.game){
                            elementIndex = index;
                            return true
                        }
                    });
                    console.log(elementIndex,gameToUpdate);
                    // debugger;
                    
                    if(gameToUpdate){
                        // gameToUpdate.options = [...data.options]
                        // if(gameToUpdate.status === 'live'){
                        //     return;
                        // }

                        // gameToUpdate.status = 'live';
                        // self.$set(gameToUpdate,'status','live');
                        
                        // self.games[elementIndex] = gameToUpdate;
                        // self.$set(self.games, elementIndex, gameToUpdate);
                        //this.getEventGames();

                        // self.$set(gameToUpdate,'status','live');
                        // self.$set(self.games, elementIndex, gameToUpdate);

                        self.$set(self.games[elementIndex], 'status', 'live');
                        this.recomputeRating();
                    }


                }
                catch(err){
                    console.error(err);
                }
                finally{
                    this.allowEmit = true;
                }
            });

            console.log('subscribed');
            
            
 

        },
        unsubscribe(){

        },

        async handleIntermissionClick(){

            try{
                
                let event = this.event;
                
                if(!event){
                    return;
                }
                    let newStatus = (this.showIntermission === true)?'live':'pending';

                    try{
                        this.$emit('show_loader');
                        // console.log(this.event.intermission);
                        
                        await GameModel.updateIntermissionStatus(event,newStatus);
                        event.intermission = newStatus;

                        

                       
                        let params = {
                            event: this.event._id
                        }
                        let action = (this.showIntermission === true)?'start':'end';
                        console.log(`intermission:${action}`);
                        // if(this.allowEmit === true){
                            // alert(`intermission:${action}`);
                            console.log(this.socket.emit(`intermission:${action}`,params));
                            // this.allowEmit = false;
                        // }
                        


                        
                        
                        //this.getEventGames();
                        // try{
                        //     this.allowEmit = false;
                        //     //await this.getEventGames();
                        // }
                        // catch(err){
                        //     console.error(err);
                        // }
                        // finally{
                        //     this.allowEmit = true;
                        // }
                    }
                    catch(e){
                        alert(e);
                    }
                    finally{
                        this.$emit('hide_loader');
                    }
                
            }
            catch(e){
                console.error(e);
            }

        },

        async handleRatingsClick(){
            try{
                
                let eventRatingObj = this.eventRatingObj;
                if(!this.initialized || !eventRatingObj){
                    return;
                }
                
                    let newStatus = (this.showRatings === true)?'live':'ended';

                    try{
                        
                        this.$emit('show_loader');
                        // console.log(this.event.rating);

                        await GameModel.updateStatus(eventRatingObj,newStatus);
                        eventRatingObj.status = newStatus;
                        // this.showRatings = newValue;
                        // this.$set(show)

                        let params = {
                            event: this.event._id,
                            game: this.event.rating
                        }
                        let action = (this.showRatings === true)?'start':'end';
                        // console.log(`game:${action}`);

                        // if(this.allowEmit === true){
                            // alert('emit');
                            this.socket.emit(`game:${action}`,params);
                            // this.allowEmit = false;
                        // }
                        


                        // await this.getEventGames();
                    }
                    catch(e){
                        alert(e);
                    }
                    finally{
                        setTimeout(()=>{
                            this.allowEmit = true;
                            this.$emit('hide_loader');
                        },600);
                    }
                
            }
            catch(e){
                console.error(e);
            }
        }


        
    },
    computed:{
        isGameFormValid(){
            /* Rules:
                1. Question lengh must be > 0
                2. Minimum answers = 2
                3. All answers must be filled
            */
            let isValid = true;

            //Validate question
            let question = this.newEvent.question
            if(typeof(question) !== 'string'
                || question.trim().length === 0
                ){
                isValid = false;
                return false;
            }

            //Validate question has valid answers
            if(this.validAnswers.length < 2){
                return false;
            }
            let answerLengths = this.validAnswers.map(ans =>{
                return ans.answer.trim().length || 0;
            });
            
            let index = answerLengths.findIndex(el=>el === 0);
            if(index > -1){
                return false;
            }

            //Check if trivia has at least one option marked as correct
            if(this.newEvent.type === 'trivia' && this.totalOptionsMarkedCorrect <= 0){
                return false;
            }

            return isValid;
        },
        validAnswers(){
            let answers = this.newEvent.options;
            if(!Array.isArray(answers)){
                return [];
            }

            answers = answers.filter(el=>{
                if(el.delete == null || el.delete === false){
                    return el;
                }
            });

            return answers;
        },
        deletedAnswers(){
            let answers = this.newEvent.options;
            if(!Array.isArray(answers)){
                return [];
            }
            
            answers = answers.filter(el=>{
                if(el._id != null && el.delete === true){
                    return el;
                }
            });

            return answers;
        },
        filteredGameResults(){
            let results = this.gameResults;
            if(!Array.isArray(results)){
                return [];
            }
            
            results = results.map(res=>{
                try{
                    
                    switch(res.data.type.trim().toLowerCase()){
                        case 'poll':
                        case 'trivia':
                            return res.data;
                    }
                }
                catch(e){
                    console.log(e);
                }
            }).filter(el => el != null);
            return results;
        },
        totalOptionsMarkedCorrect(){
            if(!this.validAnswers){
                return 0;
            }
            let total = this.validAnswers.reduce((total, option)=>{
                if(option.isCorrect){
                    return total + 1;
                }
                return total;
            },0);
            return total;
        },
        totalLiveGames(){
            let games = this.games;
            let total = 0;
            if(!Array.isArray(games)){
                return total;
            }

            
            total = games.reduce( (total,game)=>{
                if(game && game.status){
                    if(game.status === 'live'){
                        return total += 1;
                    }

                }
                return total;
            },0);
            
            return total;
        },
        eventRatingObj(){
            this.forceRecomputeCounter;
            let results = this.games;
            if(!Array.isArray(results)){
                return null;
            }
            let eventRating = null;
            
            eventRating = results.find(el => el.type === 'rating' && el._id === this.event.rating);
            return eventRating;
        },



    },
    watch:{
        event(){
            if(!this.event || this.initialized){
                return;
            }
            else{
                this.initialize();
            }
        },
        'newEvent.question'(){
            if(typeof(this.newEvent.question) === 'string'){
                //this.newEvent.question = this.newEvent.question.trim();
            }
            
        },
        'event._id'(){
            if(this.event._id){
                this.getEventGames();
            }
        },
        'event.intermission'(){
            
            if(this.event.intermission){
                this.showIntermission = (this.event.intermission === 'live')?true:false;
            }
            
        },
        eventRatingObj(){
            if(!this.eventRatingObj){
                return;
            }
            try{
                // this.allowEmit = false;
                let showRatings = (this.eventRatingObj.status === 'live')?true:false;
                if(this.showRatings !== showRatings){
                    
                    this.showRatings = showRatings;
                    
                }
                // debugger;
            }
            catch(e){
                console.log(e);
                this.showRatings = false;
            }
            finally{
                setTimeout(()=>{
                    this.allowEmit = true;
                },500);
            }
        },

        // showRatings(){
        //     if(!this.initialized || !this.allowEmit){
        //         return;
        //     }
        // }

        // async showRatings(newValue,oldValue){
        //     try{
                
        //         let eventRatingObj = this.eventRatingObj;
        //         if(!this.initialized || newValue === oldValue || !eventRatingObj || newValue == null || oldValue == null){
        //             return;
        //         }
                
        //             let newStatus = (newValue === true)?'live':'ended';

        //             try{
                        
        //                 this.$emit('show_loader');
        //                 // console.log(this.event.rating);

        //                 await GameModel.updateStatus(eventRatingObj,newStatus);
        //                 eventRatingObj.status = newStatus;
        //                 // this.showRatings = newValue;
        //                 // this.$set(show)

        //                 let params = {
        //                     event: this.event._id,
        //                     game: this.event.rating
        //                 }
        //                 let action = (newValue === true)?'start':'end';
        //                 // console.log(`game:${action}`);

        //                 if(this.allowEmit === true){
        //                     // alert('emit');
        //                     this.socket.emit(`game:${action}`,params);
        //                     this.allowEmit = false;
        //                 }
                        


        //                 // await this.getEventGames();
        //             }
        //             catch(e){
        //                 alert(e);
        //             }
        //             finally{
        //                 setTimeout(()=>{
        //                     this.allowEmit = true;
        //                     this.$emit('hide_loader');
        //                 },600);
        //             }
                
        //     }
        //     catch(e){
        //         console.error(e);
        //     }
        // },



        // async showIntermission(newValue,oldValue){
            // try{
                
            //     let event = this.event;
                
            //     if(!event || newValue == null || oldValue == null){
            //         return;
            //     }
            //         let newStatus = (this.showIntermission === true)?'live':'pending';

            //         try{
            //             this.$emit('show_loader');
            //             // console.log(this.event.intermission);
                        
            //             await GameModel.updateIntermissionStatus(event,newStatus);
            //             event.intermission = newStatus;

                        

                       
            //             let params = {
            //                 event: this.event._id
            //             }
            //             let action = (this.showIntermission === true)?'start':'end';
            //             console.log(`intermission:${action}`);
            //             if(this.allowEmit === true){
            //                 // alert(`intermission:${action}`);
            //                 console.log(this.socket.emit(`intermission:${action}`,params));
            //                 this.allowEmit = false;
            //             }
                        


                        
                        
            //             //this.getEventGames();
            //             try{
            //                 this.allowEmit = false;
            //                 await this.getEventGames();
            //             }
            //             catch(err){
            //                 console.error(err);
            //             }
            //             finally{
            //                 this.allowEmit = true;
            //             }
            //         }
            //         catch(e){
            //             alert(e);
            //         }
            //         finally{
            //             this.$emit('hide_loader');
            //         }
                
            // }
            // catch(e){
            //     console.error(e);
            // }
        // },




        // socket:{
        //     handler(newVal){
        //         if(newVal){
        //             this.subscribe();
        //         }
        //     },
        //     deep: true
        // }
    },
    async mounted(){
        // this.getEventGames();
        if(this.event ){
            this.initialized = false;
            this.initialize();
        }
        //this.timer = setInterval(()=>{ this.getEventGames(false); console.log('Timer');}, 5000);
        

        // this.connectToSocket();

        // this.socket = io(CONSTANTS.SOCKET_SERVER_URL);


        //this.subscribe();
        // setTimeout(()=>{this.registerEvents();},1000);
    },
    beforeDestroy(){
        //this.disconnectFromSocket();
        if(this.socket){
            this.socket.close()
        }
        if(this.timer){
            clearInterval(this.timer);
        }
        
    }
    
}
</script>
<style lang="scss">
.v-card__text.dialog-body{
            text-align: left !important;
        }
#event-games{

    


    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #E4E8E8;
    }
    .theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot .v-label {

        outline-color: #CC4786;
    }

    margin: 43px 0 0 auto;



    .v-btn.v-btn--disabled{
        background-color: rgb(204, 71, 134);
        border-color: rgb(204, 71, 134);
        opacity:0.8;
    }

    .cta-container{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 21px;

        .info-container{
            
            flex: 1;
            display: flex;
            justify-content: center;
            flex-flow: column;
            h1{
                font-family: graphikmedium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #363738;
                margin:0;
            }
            p{
                font-family: graphikregular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: #6C6E6F;
                margin:0;
            }
        }
    }

    .intermission{
        display:flex;
        text-align: left;
        align-items: center;

        background-color: white;
        padding: 15px;
        border: 1px solid #E4E8E8;
        border-radius: 4px;
        margin-bottom: 15px;

        .v-input--switch{
            margin:0;
        }
        .v-messages{
            display: none;
        }


        .legend{
            margin-left: 10px;
        }
        h1{
            font-family: graphikmedium;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #363738;
        }
        p{
            font-family: graphikregular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #6C6E6F;
        }
    }


    .create-edit-form{
        background: #FFFFFF;
        // box-shadow: 0px 20px 40px rgba(255, 240, 250, 0.8);
        border-radius: 8px;
        padding:25px;

        width:95vw;
        max-width: 465px;

        .title{
            margin-bottom:15px;
            display:flex;
            justify-content: space-between;
            align-items: center;

            

            i:hover{
                cursor:pointer;
            }
            h1{
                color:#363738;
                font-family: graphikmedium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                text-transform: capitalize;
            }
        }
        .lancome-textfield label{
            color:#363738;
        }
        .form-input > input{
            width: 100%;
        }

        .v-application.error,
        .lancome-textfield.error {
            background-color: transparent !important;
            label{
                color:red !important;
            }
        }
        color:#363738;
        *::placeholder{
                color:A2A9A9, 100%;
            }
        .v-select__selections > *{
            color:#363738;
            *::placeholder{
                color:A2A9A9, 100%;
            }
        }

        .v-input__control{
            color:#E4E8E8;
        }

        label{
            font-family: graphikmedium;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #363738;
            display: block;
            margin:15px 0 5px 0;
        
        }

        

        

        .v-input--checkbox{
            .v-label{
                font-family: graphikregular;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;
                color:#363738;
            }
        }

        .controls{
            display: flex;
            justify-content: space-between;

            .v-btn.v-btn--flat.v-btn--plain{
                span{
                    color: #B6BDBD !important;
                }
            }

        }

        

        .game-type-container{
            display: flex;
            flex-basis: 49%;
            min-width: 49%;
            justify-content: space-between;
            


            .game-type{
                display: flex;
                flex:1;
                flex-basis: 49%;
                max-width: 49%;
                background-color: white;
                border: 1px solid #E4E8E8;
                border-radius: 4px;
                padding:15px;

                &:hover,
                &.selected{
                    background-color: #FBEDF4;
                    border: 2.4px solid #CC4786;
                }
                
                

                
                .icon-default{
                    width:16px;
                    height:16px;
                    background-color: #E4E8E8;
                    border: 1.3px solid #B6BDBD;
                    border-radius: 10px;
                }

                .icon-selected{
                    width:16px;
                    height:16px;
                    background-color: #CC4786;
                    border: 3px solid #CF9DB5;
                    border-radius: 10px;
                }

                &:hover{
                    .icon-default{
                        width:16px;
                        height:16px;
                        background-color: #CC4786;
                        border: 3px solid #CF9DB5;
                        border-radius: 10px;
                    }
                }



                label{
                    font-family: 'circular';
                    margin:0;
                    margin-left: 8px;
                }

                &:hover{
                    cursor:pointer;
                }


            }
        }


        .add-new-container{
            color: #CC4786;
            font-family: graphikmedium;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;

            i{
                font-size: 15px;
                padding: 2px;
                border-radius: 50%;
                border: 1.5px solid #CC4786;
                color: #CC4786;
            }
            &:hover{
                cursor: pointer;
            }

            
        }

        .answer-entry{
            display: flex;
            align-items: center;

            
            & > div{
                margin-left: 5px;
            }
            & > div:first-child{
                margin-left: 0px;
            }


            .handle{
                cursor:move;
            }

            .v-text-field__details{
                display:none;
            }

            .answer-type{
                img{
                    &:hover{
                        cursor: pointer;
                    }
                }
            }


        }


    }

    .overlay-container{
        background-color:rgba(0,0,0,0.8);
        position:fixed;
        top:0;
        left:0;
        right:0;
        min-height: 100vh;
        overflow: scroll;
        z-index: 10;
        display:flex;
        justify-content: center;
        align-items:center;
        padding:20px 0;
    }

    .actions-container{
        img{
            &:hover{
                cursor: pointer;
            }
        }
    }

    .confirm-dialog{
        text-align: left;

        // .v-card__text.dialog-body{
        //     text-align: left !important;
        // }
        button{
            display: block;
        }
        .v-btn__content{
            color: #B6BDBD !important;
        }
        .confirm-controls{
            display: flex;
            justify-content: space-between;
            width: 100% !important;
            display:none;
            flex: 1;
            min-width: 100%;
        }
    }

    .games-container{
        margin-bottom:150px;
    }

    .no-games-container{
        padding:40px;
        background-color: white;
        border: 1px solid #E4E8E8;
        box-sizing: border-box;
        box-shadow: 0px 4px 16px rgba(0, 165, 156, 0.05);
        border-radius: 4px;
        margin-bottom:80px;
        padding-bottom:80px;

        img{
            width:80%;
            max-width: 446px;
        }

        h1{
            font-family: graphikmedium;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            color: #363738;
            margin-top: 30px;
        }

        p{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #6C6E6F;
            margin-top:5px;
        }


    }
    

}


        .confirm-controls{
            display: flex;
            justify-content: space-between;

            .v-btn.v-btn--flat.v-btn--plain{
                span{
                    color: #B6BDBD !important;
                }
            }

        }
</style>