<template>
    <div class="lancome-game">
        
        <!-- <pre>{{game}}</pre> -->
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header :class="gameStatus">
                    <div class="container">
                        <div @click.stop="" class="handle">
                            <img :src="lancome_handle" alt="handle">
                        </div>
                        <div class="game-type">
                            {{game.type}}
                        </div>
                        <div class="game-status" :class="gameStatus">
                            <!-- <v-chip :class="gameStatus">{{gameStatus}}</v-chip> -->
                            {{gameStatus}}
                        </div>
                        <div class="actions-container" > <!-- v-if="gameStatus === 'pending'" -->
                            <img @click.stop="handleDelete()" :src="lancome_trash" alt="Delete game">
                        </div>

                        <div class="show-hide-results">
                            <div class="borders">
                                <v-switch
                                    color="#CC4786"
                                    v-model="showResults"
                                    @click.stop="handleShowHideResults()"
                                ></v-switch>
                                <h1>Show everyone the results</h1>
                            </div>
                        </div>
                        
                    </div>
                    
                    
                    
                </v-expansion-panel-header>
                <v-expansion-panel-content>


                    <div class="game-status-container">

                        <h1 class="question">{{game.question}}</h1>

                        <div class="game-options-container">
                            
                            <div v-for="option in renderOptions" :key="option._id" class="option" :class="{'correct': option.isCorrect}">
                                <div class="row-container">
                                    <h1 class="title" :class="{'correct': option.isCorrect}">{{option.answer}}</h1>
                                    <h1 class="status">{{option.votes}} votes / {{(totalResults > 0)?Math.round((option.votes*100)/totalResults):0}}%</h1>
                                </div>
                                <div class="progress">
                                    <v-progress-linear 
                                        :value="(totalResults > 0)?Math.round((option.votes*100)/totalResults):0"
                                        :color="(option.isCorrect)?'#19CCAC':'#B6BDBD'"
                                        height="8"
                                        ></v-progress-linear>
                                </div>
                            </div>
                        </div>
                    

                        <div class="actions-container" v-if="gameStatus === 'pending'">
                            <div v-if="game.type === 'trivia'" class="information">
                                {{(totalResults > 0)?Math.round((totalCorrect*100)/totalResults):0}}% correct<br>
                                {{totalCorrect}} / {{totalResults}} answers correct
                            </div>
                            <div v-else class="information">
                                Total votes: {{totalResults}} votes
                            </div>
                            <div class="actions">
                                <v-btn
                                    outlined
                                    large
                                    color="#CC4786"
                                    dark
                                    v-if="totalResults===0"
                                    @click="$emit('editgame',game)"
                                >
                                    Edit
                                </v-btn>

                                <v-btn
                                    color="#CC4786"
                                    large
                                    :disabled="(totalLiveGames && totalLiveGames > 0)?true:false"
                                    @click="handleGoLive()"
                                >
                                    Launch
                                </v-btn>
                            </div>
                        </div>
                        <div class="actions-container" v-if="gameStatus === 'live'">
                            <div v-if="game.type === 'trivia'" class="information">
                                {{(totalResults > 0)?Math.round((totalCorrect*100)/totalResults):0}}% correct<br>
                                {{totalCorrect}} / {{totalResults}} answers correct
                            </div>
                            <div v-else class="information">
                                Total votes: {{totalResults}} votes
                            </div>
                            <div class="actions">
                                

                                <v-btn
                                    color="#CC4786"
                                    large
                                    
                                    @click="handleEndGame"
                                >
                                    End {{game.type}}
                                </v-btn>


                                <!-- <v-btn
                                    color="#CC4786"
                                    large
                                    
                                    @click="game.status = pending"
                                >
                                    Pending {{game.type}}
                                </v-btn> -->
                            

                            </div>
                        </div>
                        <div class="actions-container" v-if="gameStatus === 'ended'">
                            <div v-if="game.type === 'trivia'" class="information">
                                {{(totalResults > 0)?Math.round((totalCorrect*100)/totalResults):0}}% correct<br>
                                {{totalCorrect}} / {{totalResults}} answers correct
                            </div>
                            <div v-else class="information">
                                Total votes: {{totalResults}} votes
                            </div>

                            <div class="actions">
                                

                                <!-- <v-btn
                                    color="#CC4786"
                                    large
                                    
                                    @click="game.status = pending"
                                >
                                    Pending {{game.type}}
                                </v-btn> -->
                            </div>
                            
                            
                        </div>



                        <!-- <pre>{{game}}</pre>  -->


                    </div>


                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script> 
import lancome_handle from '../../assets/img/lancome_handle.svg';
import lancome_trash from '../../assets/img/lancome_trash.svg';
export default {
    props:['game', 'totalLiveGames'],
    data(){
        return {
            lancome_handle,
            lancome_trash,
            showResults : (this.game.showResults)?true:false,
        };
    },
    computed:{
        renderOptions(){
            if(this.game && this.game.results && this.game.results.options && Array.isArray(this.game.results.options)){
                return this.game.results.options;
            }
            return [];
        },
        gameStatus(){
            let status = 'pending';
            if(this.game && this.game.status){
                switch(this.game.status){
                    case 'live':
                    case 'ended':
                        status = this.game.status;
                }
            }
            return status;
        },
        totalCorrect(){
            let total = 0;
            try{
                total = this.game.results.options.reduce((total,el)=>{
                    if(el.isCorrect && el.votes > 0){
                        return total + el.votes
                    }
                    else{
                        return total;
                    }
                },0);
            }catch{
                total = 0;
            }
            return total;
        },
        totalIncorrect(){
            let total = 0;

            try{
                total = this.game.results.options.reduce((total,el)=>{
                    if(!el.isCorrect && el.votes > 0){
                        return total + el.votes
                    }
                    else{
                        return total;
                    }
                },0);
            }catch{
                total = 0;
            }
            return total;
        },
        totalResults(){
            return this.totalCorrect + this.totalIncorrect;
        }
        
    },
    methods:{
        handleDelete(){
            this.$emit('deletegame',this.game);
        },
        sortResults(){
            // let result = this.game.options.map((op) => {
            //     let results = this.game.results.options;
            //     let result = results.find((res)=>{
            //         return res._id == op._id;
            //     }); 
            // });
            
        },
        handleGoLive(){
            this.$emit('golive',this.game);
            //this.$set(this.game,'status','live');
        },
        handleEndGame(){
            this.$emit('endgame',this.game);
            //this.$set(this.game,'status','ended')
        },
        handleShowHideResults(){
            this.game.showResults = this.showResults;
            this.$emit('showhideresults',this.game)//this.showResults);
        },
    },
    watch:{
        game(){
            if(this.game != null){
                this.sortResults();
            }
        },
        "game.showResults"(){
            this.showResults = (this.game.showResults)?true:false;
        }
    }
}
</script>
<style lang="scss">
.lancome-game{
    margin:10px 0;


    .v-expansion-panel{
        &::before{
            box-shadow: none;
        }

        .v-expansion-panel-header--active{
            height: 55px;
            max-height: 55px;
        }

        .v-expansion-panel-header{
            height: 55px;
            max-height: 55px;
            background-color: #E4E8E8;
            padding:14px 20px;
            

            &::before{
                content: ' ';
                width:7px;
                height:47px;
                background-color: #B6BDBD;
                border-radius: 0px 4px 4px 0px;
                position: absolute;
                z-index: 10;
                left: 0;
                opacity: 1;
                bottom: 0;
                top: 50%;
                transform: translate(0%,-50%);
                opacity: 1 !important;
            }

            &.pending{
                &::before{
                    background-color: #FF7596;
                }
            }
            &.live{
                &::before{
                    background-color: #1ACCAC;
                }
            }

            .container{
                display: flex;
                align-items: center;
                // flex: 1;
                .handle{
                    margin-right:15px;
                    &:hover{
                        cursor:move;
                    }
                }
                .game-type{
                    text-transform: capitalize;
                    font-family: graphikmedium;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color:#363738;
                }
                .game-status{
                    background-color: #B6BDBD;
                    border-radius: 16px;
                    font-family: 'circular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 9px;
                    line-height: 145.47%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.25em;
                    text-transform: uppercase;
                    color: #6C6E6F;
                    padding: 3px 8px;
                    margin-left:15px;

                    &.pending{                      
                        background-color: #FF7596;
                        color: #FFFFFF;
                    }
                    &.live{
                        background-color: #1ACCAC;
                        color: #FFFFFF;
                    }

                    
                }

                
                
                
            }



        }

    }
    
    .show-hide-results{
        display: flex;
        // border: 1px solid #CED6D6;
        // border-top:none;
        // border-bottom:none;
        // padding: 3px 5px;
        max-height: 100%;
        align-items: center;
        // flex-shrink:0;
        justify-content: flex-end;
        margin-left: auto;
        .borders{
            display: flex;
            border: 1px solid #CED6D6;
            border-top:none;
            border-bottom:none;
            padding: 3px 15px;
            align-items: center;
            height: 35px;
        }
        h1{
            display:flex;
            // flex-shrink:0;
            font-family: graphikmedium;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
            color: #6C6E6F;
        }
    }

    .actions-container{
        margin-left: 15px;
        img{
            &:hover{
                opacity: 0.8;
                cursor: pointer;
            }
        }

        display: flex;
        justify-content: space-between;
        .information{
            display: flex;
            text-align: left;
            font-family: graphikmedium;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #6C6E6F;
        }
        .actions{
            display: flex;
            button{
                margin-left:5px;
                &:first-child{
                    margin-left: 0px;
                }
            }
        }
        
    }

    .v-btn--outlined{
        span{
            color:#CC4786 !important;
        }
    }

    .game-status-container{
        width: 100%;
        padding: 10px 30px;
        text-align: left;
        .question{
            font-family: graphikmedium;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #6C6E6F;
            display:block;
        }

        .actions-container{
            margin:15px 0;
            margin-top:25px;
        }

        .game-options-container{
            margin:15px 0;
            
            h1{
                display: block;
                font-family: graphikmedium;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                // line-height: 15px;
                color: #6C6E6F;
            }

            .title{
                font-family: graphikregular;
                font-style: normal;
                font-weight: normal;
                font-size: 14px !important;
                // line-height: 15px;
                color: #6C6E6F;
            }
            .row-container{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                // display:none;
                width: 100%;
                margin: 0;
            }


            .option{
                margin: 5px 0;
                .correct{
                    color:#19CCAC;
                    .v-progress-linear__background{
                        
                        opacity: 0.3;
                        left: 10%;
                        width: 90%;
                        background-color: #6C6E6F !important;
                        border-color: #6C6E6F !important;
                    }
                }
            }
        }
    }
    .v-progress-linear__background{
        opacity: 1 !important;
        background-color: #E4E8E8 !important;
        border-color: #E4E8E8 !important;
    }
    .correct{
        color:#19CCAC;
        .v-progress-linear__background{
            opacity: 1 !important;
            background-color: #E4E8E8 !important;
            border-color: #E4E8E8 !important;
        }
    }

}



</style>