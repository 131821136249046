import { render, staticRenderFns } from "./AttendeeInfoBreakdown.vue?vue&type=template&id=3f3823b9&"
import script from "./AttendeeInfoBreakdown.vue?vue&type=script&lang=js&"
export * from "./AttendeeInfoBreakdown.vue?vue&type=script&lang=js&"
import style0 from "./AttendeeInfoBreakdown.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VDataTable,VSelect})
