<template>
    <div class="report-section attendeebreakdown">
        <div class="report-title">
            <h1>Store Registration breakdown (General)</h1>
            <!-- <p></p> -->
        </div>
        
        <div class="report-row">
            <template>
            <v-data-table
                :headers="storeHeaders"
                :items="storeData"
                :items-per-page="5"
                class="elevation-1"
            ></v-data-table>
            </template>

        </div>



        <div class="report-title">
            <h1>Store Registration breakdown</h1>
            <!-- <p></p> -->
        </div>

        <v-select
            color="#CC4786"
            v-model="store2chain"
            height="40"
            outlined
            block
            :items="store2chains"
            
            placeholder="Filter by chain"
            item-text="label"
            return-object
        ></v-select>
        
        <div class="report-row">
            <template>
            <v-data-table
                :headers="store2Headers"
                :items="store2Data"
                :items-per-page="5"
                :options.sync="store2options"
                :server-items-length="store2count"
                :loading="store2loading"
                class="elevation-1"
            ></v-data-table>
            </template>

        </div>


        <div class="report-title">
            <h1>Skin Wish breakdown</h1>
            <!-- <p></p> -->
        </div>
        
        <div class="report-row">
            <template>
            <v-data-table
                :headers="goalHeaders"
                :items="goalData"
                :items-per-page="5"
                class="elevation-1"
            ></v-data-table>
            </template>

        </div>


    </div>
</template>
<script>
import EventModel from '../../../lib/EventModel';
export default {
    props:['event'],
    data(){
        return{
            storeInfo:null,
            goalsInfo:null,
            storeHeaders:[
                { text: 'Chain', value: 'chain', sortable: true },
                { text: 'Total', value: 'total', sortable: true, align: 'center' },
                // { text: 'Type', value: 'type', sortable: true }
            ],
            goalHeaders:[
                { text: 'Skin Wish', value: 'goal', sortable: true },
                { text: 'Total', value: 'total', sortable: true, align: 'center' },
                // { text: 'Type', value: 'type', sortable: true }
            ],




            store2Headers:[
                { text: 'Store', value: 'store', sortable: true },
                { text: 'Total', value: 'total', sortable: true, align: 'center' },
                // { text: 'Type', value: 'type', sortable: true }
            ],
            store2count:0,
            store2currentPage:1,
            store2loading:false,
            store2options:{},
            store2RawData:null,
            store2chain:{},
            store2chains:[
                {"value":"All", "label":"All"},
                {"value":"BELK", "label":"BELK"},
                {"value":"BLOOMINGDALES", "label":"BLOOMINGDALES"},
                {"value":"DILLARDS", "label":"DILLARDS"},
                {"value":"JULIAN GOLD", "label":"JULIAN GOLD"},
                {"value":"MACY'S", "label":"MACY'S"},
                {"value":"MCCAULOUS", "label":"MCCAULOUS"},
                {"value":"NAVY", "label":"NAVY"},
                {"value":"NEIMAN MARCUS", "label":"NEIMAN MARCUS"},
                {"value":"NORDSTROM", "label":"NORDSTROM"},
                {"value":"SAKS FIFTH AVENUE", "label":"SAKS FIFTH AVENUE"},
                {"value":"ULTA", "label":"ULTA"},
                {"value":"NONE", "label":"Not a chain store"},
                {"value":"NONE", "label":"Undefined store"}
            ]


        }
    },
    computed:{
        storeData(){
            if(!this.storeInfo || !this.storeInfo.chains){
                return [];
            }
            let result = [...this.storeInfo.chains];
            return result;
        },
        store2Data(){
            if(!this.store2RawData || !this.store2RawData.stores){
                return [];
            }
            let result = [...this.store2RawData.stores];
            return result;
        },
        goalData(){
            if(!this.goalsInfo){
                return [];
            }
            
            let rawresult = this.goalsInfo;
            // debugger;
            let result = Object.keys(rawresult[0]).map(key=>{
                let obj = {};
                let goal = key.replace("Total_","");
                
                obj.goal = goal;
                obj.total = +rawresult[0][key];

                return obj;
                
            });
            // console.log(result);
            // debugger;
            return result;
        }
    },
    methods:{
        async loadTableData(){
            if(!this.event){
                return
            }
            // await Promise.all([
                this.loadStoreTableData();
                this.loadGoalsTableData();
                this.loadStore2TableData();
            // ]); 
        },
        async loadStoreTableData(){
            if(this.event && this.event.sandia){
               this.storeInfo = await EventModel.getStoreAttendeeTotals(this.event.sandia);
            //    console.log(this.storeInfo);
            }
        },
        async loadStore2TableData(){
            try{
                
                
                if(this.event && this.event.sandia){

                    this.store2loading = true;
                    let sortBy, sortDesc, page, itemsPerPage;

                    if(this.store2options){
                        ({ sortBy, sortDesc, page, itemsPerPage } = this.store2options)
                    }

                    

                    let currentPage = (page)?page:1;
                    let pageLimit = (itemsPerPage)?itemsPerPage:5;
                    let firstRecord = (currentPage - 1) * pageLimit;
                    let orderField = (sortBy && Array.isArray(sortBy) && sortBy.length > 0)?sortBy[0]:"total";
                    let orderDirection = (sortDesc && Array.isArray(sortDesc) && sortDesc.length > 0 && sortDesc[0] === true)?"DESC":"ASC";
                    let query = (this.store2chain && this.store2chain.value && this.store2chain.value !== "All")?this.store2chain.value:"";

                
                    let filter = {
                        "first_record":firstRecord,
                        "record_limit":pageLimit,
                        "order_by":orderField,
                        "order_dir":orderDirection,
                        "chain":query
                    }
                    // console.log(filter);
                    
                    this.store2RawData = await EventModel.getStore2Totals(this.event.sandia, filter);
                    // console.log(this.store2RawData);
                    // debugger
                    this.store2count = this.store2RawData.count;
                }
            }
            finally{
                this.store2loading = false;
            }
        },
        async loadGoalsTableData(){

            if(this.event && this.event.sandia){
               this.goalsInfo = await EventModel.getAttendeeGoalsTotals(this.event.sandia);
               
            }
        }
    },
    watch:{
        event(){
            this.loadTableData();
        },
        store2options:{
            handler(){
                this.loadStore2TableData();
            },
            deep:true
        },
        store2chain(){
            if(this.store2chain && this.store2chain.value === "All"){
                this.store2chain = {};
            }
            this.loadStore2TableData();
        }
    },
    async mounted(){
        await this.loadTableData();
    }
}
</script>
<style lang="scss">

    .mdi-chevron-left::before,
    .mdi-chevron-right::before{
        color:#BDBDBD;
    }

    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #E4E8E8;
    }
    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #E4E8E8;
    }
    .v-input__slot:hover{
        color: #E4E8E8 !important;
    }

</style>