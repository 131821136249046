import CryptoJS from "crypto-js";

const Auth = {
    generateCodeVerifier : () => {
        let code_verifier = Auth.generateRandomString(128)
        return code_verifier;
    },
    generateRandomString : (length) => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    },
    generateCodeChallenge: (code_verifier) => {
        let code_challenge = Auth.base64URL(CryptoJS.SHA256(code_verifier));
        return code_challenge;
    },
    base64URL: (string) =>{
        return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
    },

    storeCredentials: async (data)=>{
        //alert(JSON.stringify(data));

        if(!data){
            throw ('No credentials to store');
        }

       
        await Promise.all(
            Object.entries(data).map(async (key)=>{
                await localStorage.setItem(key[0], key[1]);
            })
        );

        

        //alert('stored response');
    },

    fetchCredentials: ()=>{
        let storage = {};
        Object.keys(localStorage).forEach(k=>{
            let val = localStorage.getItem(k);
            if(val){
                storage[k] = val;
            }
        })
        return storage;
    },

    clearCredentials:()=>{
        localStorage.clear();
    }

}

export default Auth;