<template>
    <div id="login-view">
        <div class="login-container">
            <img class="logo" :src="logo" alt="Lancome Logo">
            <div class="card">

                <p class="error" v-if="this.loginError">
                    {{this.loginError}}
                </p>
               
                <TextInput 
                    label='email'
                    v-model="email"
                    placeholder="name@company.com"
                    :invalid="$v.email.$dirty && $v.email.$invalid"
                    :errorMessage="emailError"
                    @input="$v.email.$touch()"
                    style="background-color:transparent"
                />
                <TextInput 
                    label='Password'
                    v-model="password"
                    type="password"
                    placeholder=""
                    :invalid="$v.password.$dirty && $v.password.$invalid"
                    :errorMessage="passwordError"
                    @input="$v.password.$touch()"
                    style="background-color:transparent"
                />

                <v-btn
                color="#CC4786"
                large
                block
                @click="handleSignIn"
                v-on:keyup.enter="handleSignIn"
                :loading="signingIn"
                >Sign In</v-btn>

            </div>
            
        </div>
    </div>
</template>
<script>
// import background from '../assets/img/lancome_background.png';
import logo from '../assets/img/lancome_logo.svg';
import TextInput from '../components/TextInput/TextInput.vue';
import { required, minLength, email } from 'vuelidate/lib/validators';
import axios from 'axios';
import CONSTANTS from '../lib/Constants.js';
import Auth from '../lib/Auth.js';

export default {
    components:{TextInput},
    data(){
        return{
            logo,
            CONSTANTS,
            email:'',//'seth@hellodebrain.com',
            password:'',//'123456!',
            signingIn:false,
            loginError:null
        }
    },
    validations: {
        email: {
            required,
            minLength: minLength(4),
            email
        },
        password:{
            required,
            minLength: minLength(6),
        }
    },
    computed:{
        emailError(){
            return this.getErrorMessage(this.$v.email);
        },
        passwordError(){
            return this.getErrorMessage(this.$v.password);
        }
    },
    methods:{
        getErrorMessage(model){
            if(model == null || !model.$dirty){
                return '';
            }
            
            let message = '';
            // debugger;
            if(typeof(model.required) !== 'undefined' && !model.required){
                message = "This field is required";
            }
            if(typeof(model.minLength) !== 'undefined' && !model.minLength){
                message = "You must enter at least " + model.$params.minLength.min + " characters";
            }
            if(typeof(model.sameAsPassword) !== 'undefined' && !model.sameAsPassword){
                message = "Entered value doesn't match";
            }
            if(typeof(model.email) !== 'undefined' && !model.email){
                message = "Value must be valid email";
            }
           
            return message;
        },
        isFormValid(){
            let isValid = true;

            if(this.$v.email.$invalid){
                isValid = false;
            }
            if(this.$v.password.$invalid){
                isValid = false;
            }

            return isValid;
        },
        async handleSignIn(){
            this.loginError = null;
            let isFormValid = this.isFormValid();
            if(!isFormValid){
                return;
            }

            this.signingIn = true;
            let url = this.CONSTANTS.API_MONGO_BASE_URL + 'api/auth/login';
            let payload = {
                "email": this.email,
                "password": this.password
            };
            let headers = null;
            axios.post(url,payload,headers).then(async (result)=>{

                let isAdmin = false;
                console.log(result.data.data.user);

                if( result.data.data.user.profile == 'admin' )
                    isAdmin = true;
            
                    
                    
                if(result.data.success){
                    let credentials = {
                        token: result.data.data.token,
                        firstName: result.data.data.user.firstName,
                        lastName: result.data.data.user.lastName,
                        profile: result.data.data.user.profile,
                        id: result.data.data.user._id,
                        email: result.data.data.user.email,
                        isAdmin: isAdmin
                    }
                    
                    await Auth.storeCredentials(credentials);
                    this.$router.push({name:'Admin'});
                }
                

            })
            .catch(error=>{
                this.loginError = error.response.data.error.detail || "Error: Invalid credentials";
                
            })
            .finally(()=>{
                this.signingIn = false;
            });
        }
    }
        
}    
</script>
<style lang="scss">
    @import "@/styles/common.scss";
    

    #login-view{
        min-height: 100vh;
        display: flex;
        justify-content: center;
        margin-top:30px;
        @media screen and (min-width:600px){
            &{
                margin-top:0;
                align-items: center;
            }
        }
        
        
        background-color: #F7F9F9;
        background: url('../assets/img/lancome_background.png') no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;


        .v-application{
             &.error{
                background-color: transparent !important;
                border:none;
             }
        }
        

        .login-container{
            max-width: 500px;
            width: 95%;
            .logo{
                margin-bottom: 63px;
            }
            .card{
                text-align: left;
                padding:44px 32px;

                p.error{
                    color:red;
                    text-align: center;
                    margin-bottom:15px;
                    background-color: transparent !important;
                }
            }
            .lancome-textfield{
                margin-bottom:25px;
                width: 100%;
            }
        }

        .lancome-textfield .form-input input{
            width: 100%;
        }

        .v-application.error,
        .lancome-textfield.error{
            background-color: transparent !important;
            border:none !important;
        }

    
    }
    // .v-application.error,
    // .lancome-textfield.error{
    //     background-color: transparent !important;
    //     border:none !important;
    // }
</style>