import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';


import Button from 'material-components-vue/dist/button';
import vuetify from './plugins/vuetify';
import UUID from "vue-uuid";
import moment from 'moment'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'





Vue.use(Chartkick.use(Chart));
Vue.filter('numFormat', numFormat(numeral));

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  // value = moment.utc(value).utcOffset('-0500').format("MMMM Do, YYYY h:mmA") + ' EST';
  value = moment.utc(value).utcOffset('-0500').format("MMMM Do, YYYY");
  return value.charAt(0).toUpperCase() + value.slice(1)
});
Vue.filter('formatDateTime', function (value) {
  
  if (!value) return ''


   const pad = (value) =>{
      return value < 10 ? '0' + value : value;
  }
  const createOffset = (date) =>{
      var sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
      var offset = Math.abs(date.getTimezoneOffset());
      var hours = pad(Math.floor(offset / 60));
      var minutes = pad(offset % 60);
      return sign + hours + ":" + minutes;
  }


  // value = moment.utc(value).utcOffset('-0500').format("MMMM Do, YYYY h:mmA") + ' EST';
  let dateObj = moment.utc(value);
  let offset = createOffset(new Date());
  value = dateObj.utcOffset(offset).format("MMM Do, h:mmA");
  return value.charAt(0).toUpperCase() + value.slice(1)
})


Vue.use(UUID);
Vue.use(Vuelidate);
Vue.use(Button);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  
  render: h => h(App)
}).$mount('#app')
